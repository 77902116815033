import {Sidebar} from "../components/organisms/sidebar/sidebar";
import {Outlet} from "react-router-dom";

export const HomeLayout = () => {
    return (
        <div className="flex h-screen">
            <div className="w-64 flex-none flex-col border-r border-solid border-color-border-side pt-6 pb-6 pl-3 pr-3">
                <Sidebar/>
            </div>
            <div className="flex-1 overflow-y-auto">
                <div className="m-8">
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}