import {Area, AreaChart, Line, ResponsiveContainer, Tooltip} from "recharts";
import {Chip} from "@nextui-org/react";
import {StatIcon} from "../../../icons/stat";
import {useEffect, useState} from "react";
import axios from "axios";
import {api} from "../../../utils/axios.config";
import {useNavigate} from "react-router-dom";

export const AreaBottomChartStage = ({stage, name, color}) => {

    const [data, setData] = useState([]);
    const [difference, setDifference] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${api}/analytic/week?stage=${stage}`).then(response => {
            setData(response.data.items);
            setDifference(response.data.difference);
        })
            .finally(() => setLoading(false))
    }, [])

    if(loading) return "Loading..."

    return (
        <div className="bg-gray-100 rounded-xl max-h-[500px] relative">
            <StatIcon onClick={() => navigate("/stage/" + stage)} className="cursor-pointer absolute top-[10px] right-[15px]"/>
            <div className="flex w-full justify-between pl-5 pt-5 pr-5 items-center">
                <p>{name}</p>
            </div>
            <div className="pt-5 flex flex-col w-full pt-2 items-center">
                <p className="text-gray-500">за день</p>
                <p className="font-bold text-xl mb-5">{data[0].value} шт.</p>
                {difference >= 0 ?
                    <Chip color='success' className="text-white mb-2">+{difference}%</Chip>
                    :
                    <Chip color='danger' className="text-white mb-2">-{difference}%</Chip>
                }
            </div>
            <div className="w-full h-[150px]">
                 <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={data}>
                        <Tooltip />
                        <Line type="monotone" dataKey="value" stroke={color} activeDot={{ r: 2 }} />
                        <Area type="monotone" dataKey="value" stroke={color} fill={color}/>
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}