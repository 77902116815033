import {
    Bar,
    CartesianGrid,
    Legend,
    Tooltip,
    XAxis,
    YAxis,
    BarChart,
    ReferenceLine,
    Line,
    Area,
    LineChart,
    AreaChart, ResponsiveContainer
} from "recharts";
import {Button, ButtonGroup, Chip} from "@nextui-org/react";
import {useCallback, useEffect, useMemo, useState} from "react";
import axios from "axios";
import {api} from "../../../utils/axios.config";
import {types} from "../../pages/scanning/scanning";

export const BarChartStage = () => {

    const [typeActive, setTypeActive] = useState(types[0]);
    const [timeActive, setTimeActive] = useState(1);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [today, setToday] = useState(0);
    const [tomorrow, setTomorrow] = useState(0);
    const [weekago, setWeekago] = useState(0);
    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        axios.get(`${api}/analytic/full/day`).then(response => {
            setData(response.data);
        })
            .finally(() => setLoading(false))
    }, []);

    const items = useMemo(() => {
        const filteredData = JSON.parse(JSON.stringify([...data]));
        for (const day of filteredData) {
            day.stages = day.stages.filter(stage => stage.stage === typeActive.xvalue);
        }

        const result = [];
        filteredData.map(day => {
        day.stages[0].items.forEach((item, index) => {
            switch (day.time) {
                case 'TODAY':
                    result.push({"ty" : item.value, "time" : item.time})
                    break;
            }
        });
        });

        const groupedData = result.reduce((acc, item) => {
            const { time, ...rest } = item;
            acc[time] = acc[time] || [];
            acc[time].push(rest);
            return acc;
        }, {});

        const a = Object.keys(groupedData).map(time => {
            return { time, ...groupedData[time].reduce((acc, item) => ({ ...acc, ...item }), {}) };
        });
            console.log(a);

        setToday(filteredData.filter(x => x.time === "TODAY")[0]?.stages[0].amount)
        setTomorrow(filteredData.filter(x => x.time === "TODAY")[0]?.stages[0].amount - filteredData.filter(x => x.time === "TOMOROW")[0]?.stages[0].amount)
        setWeekago(filteredData.filter(x => x.time === "TODAY")[0]?.stages[0].amount - filteredData.filter(x => x.time === "WEEKAGO")[0]?.stages[0].amount)

        return a;
    }, [typeActive, data]);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ backgroundColor: 'transparent' }}>
                    <p>{`${label} : ${payload[0].value}`}</p>
                </div>
            );
        }
        return null;
    };

    if(loading) return "Loading..."

    return (
        <div className="bg-gray-100 rounded-xl p-5 h-full">
            <div className="flex gap-5">
                <ButtonGroup>
                    {types.map(x => {
                        return (
                            <Button onPress={() => setTypeActive(x)} size="sm" color={x.id === typeActive.id ? "warning" : "default"} className="text-white" key={x.id}>{x.value}</Button>
                        )
                    })}
                </ButtonGroup>
            </div>
            <div className="flex items-center gap-10 pt-10 pb-10">
                    <p className="text-bold ">Сводка за {timeActive === 1? "cегодня" : "неделю"}</p>
                    <div>
                        <p className="font-bold">{today} шт.</p>
                        <p className="text-gray-500">Количество</p>
                    </div>
                    <div>
                        <p className={tomorrow >= 0 ? "text-success" : "text-danger"}>{tomorrow} шт.</p>
                        <p className="text-gray-500">от вчера</p>
                    </div>
                    <div>
                        <p className={weekago >= 0 ? "text-success" : "text-danger"}>{weekago} шт.</p>
                        <p className="text-gray-500">от прошлой недели</p>
                    </div>

            </div>
                <div className="w-full h-[450px]">
                    <ResponsiveContainer width="100%" height="100%" className="p-1">
                        <BarChart data={items}>
                            <YAxis stroke="transparent" axisLine={false} tickLine={false}/>
                            <XAxis dataKey="time" axisLine={false} tickLine={false} stroke="#fff"/>
                            <Bar dataKey="ty" className="hover:fill-[#816BFF] fill-default" stroke="none" barSize={70} radius={[20, 20, 20, 20]}
                                 onMouseOver={(data, index) => setActiveIndex(index)}
                                 onMouseOut={() => setActiveIndex(null)} />
                            {activeIndex !== null && (
                                <>
                                    <ReferenceLine y={items[activeIndex].ty} stroke="#816BFF" strokeDasharray="3 3" />
                                    <ReferenceLine y={items[activeIndex].ty} label={{ value: items[activeIndex].ty, position: 'insideLeft', offset: -30, stroke: "#fff" }} stroke="transparent" />
                                </>
                            )}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
        </div>
    )
}