import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Button,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    DropdownSection,
    DropdownItem,
    cn,
    Input,
    Select,
    SelectItem,
    Pagination,
    useDisclosure,
    ModalFooter, ModalBody, ModalHeader, ModalContent, Modal
} from "@nextui-org/react";
import {useCallback, useEffect, useMemo, useState} from "react";
import axios from 'axios';
import {api} from "../../../utils/axios.config";
import {ActionsIcon} from "../../../icons/actions";
import {DownloadFileIcon} from "../../../icons/download_file";
import {RemoveFileIcon} from "../../../icons/remove_file";
import {CrossIcon} from "../../../icons/cross";
import {SearchIcon} from "../../../icons/search";
import {ChevronDownIcon} from "../../../icons/chevron_down";
import {useNavigate} from "react-router-dom";
import {UploadShkModal} from "../../molecules/uploadShkModal/uploadShkModal";
import {formatDate} from "../../../utils/formatDate";

const columns = [
    {name: "НАЗВАНИЕ", uid: "name"},
    {name: "ДАТА", uid: "date"},
    {name: "МОДЕЛЕЙ", uid: "total"},
    {name: "СКЛАД", uid: "stock"},
    {name: "МАГАЗИН", uid: "shop"},
    {name: "ДЕЙСТВИЯ", uid: "actions"},
];
export const iconClasses = "text-xl text-default-500 pointer-events-none flex-shrink-0";

const statusOptions = [
    {name: "Active", uid: "active"},
    {name: "Paused", uid: "paused"},
    {name: "Vacation", uid: "vacation"},
];

const INITIAL_VISIBLE_COLUMNS = ["name", "date", "total", "stock", "shop", "actions"]

export const SuppliesPage = () => {
    const [filterValue, setFilterValue] = useState("");
    const [selectedKeys, setSelectedKeys] = useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [statusFilter, setStatusFilter] = useState("all");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortDescriptor, setSortDescriptor] = useState({
        column: "name",
        direction: "ascending",
    });
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const { isOpen: isOpenShk, onOpen: onOpenShk, onOpenChange: onOpenChangeShk } = useDisclosure();
    const [idShop, setIdShop] = useState(0);
    const navigator = useNavigate();

    useEffect(() => {
        axios.get(`${api}/bid/list`).then(response => {
            setData(response.data)
        })
    }, [])

    const hasSearchFilter = Boolean(filterValue);

    const headerColumns = useMemo(() => {
        if (visibleColumns === "all") return columns;
        return columns.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);

    const filteredItems = useMemo(() => {
        let filteredUsers = [...data];

        if (hasSearchFilter) {
            filteredUsers = filteredUsers.filter((user) =>
                user.name.toLowerCase().includes(filterValue.toLowerCase()),
            );
        }
        if (statusFilter !== "all" && Array.from(statusFilter).length !== statusOptions.length) {
            filteredUsers = filteredUsers.filter((user) =>
                Array.from(statusFilter).includes(user.status),
            );
        }

        return filteredUsers;
    }, [data, filterValue, statusFilter]);

    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const items = useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);

    const sortedItems = useMemo(() => {
        return [...items].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);

    const deleteSupplie = (id) => {
        axios.delete(`${api}/bid?id=${id}`).then(response => {
            window.location.reload();
        })
    }

    const assemblyDownload = async (id) => {
        const response = await axios.get(`${api}/report/assembly?id=${id}`, {
            responseType: 'blob',
        });
        const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `assembly-${id}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const patternDownload = async (id) => {
        const response = await axios.get(`${api}/report/pattern/wb?id=${id}`, {
            responseType: 'blob',
        });
        const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `pattern-${id}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const patternOzonDownload = async (id) => {
        const response = await axios.get(`${api}/report/pattern/ozon?id=${id}`, {
            responseType: 'blob',
        });
        const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `pattern-${id}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const shkDownload = async (id) => {
        const response = await axios.get(`${api}/report/shk?id=${id}`, {
            responseType: 'blob',
        });
        const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `shk-${id}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const stickerOzonDownload = async (id) => {
        const response = await axios.get(`${api}/report/sticker/ozon?id=${id}`, {
            responseType: 'blob',
        });
        const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `code128-${id}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const stickerWBDownload = async (id) => {
        const response = await axios.get(`${api}/report/sticker/wb?id=${id}`, {
            responseType: 'blob',
        });
        const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `ean13-${id}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleShkModal = (shop) => {
        setIdShop(shop);
        onOpenShk();
    }

    const renderCell = useCallback((x, columnKey) => {
        switch (columnKey) {
            case "shop":
                return (
                    <span>{x.shop.name}</span>
                )
            case "stock":
                return (
                    <span>{x.stock.name}</span>
                )
            case "date":
                return (
                    <span>{formatDate(x.date)}</span>
                )
            case "name":
                return (
                    <span>{x.name}</span>
                )
            case "total":
                return (
                    <span>{x.boxes?.map(x => x.items).flat().reduce((sum, item) => sum + Number(item.amount), 0)} </span>
                )
            case "actions":
                return (
                    <div>
                        <Dropdown>
                            <DropdownTrigger>
                                <Button color="secondary">
                                    <ActionsIcon className="cursor-pointer" width="20" height="20"/>
                                    <span>Управление</span>
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu variant="faded" aria-label="Dropdown menu with description">
                                <DropdownSection title="Действия" showDivider>
                                    <DropdownItem
                                        key="new"
                                        onClick={() => navigator("/supplies/edit/" + x.id)}
                                        description="Редактировать заявку"
                                        startContent={<DownloadFileIcon width="20" height="20" className={iconClasses} />}
                                    >
                                        Редактирование
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => handleShkModal(x.id)}
                                        key="edit"
                                        description="Список шк для коробов"
                                        startContent={<DownloadFileIcon width="20" height="20" className={iconClasses} />}
                                    >
                                        Внести ШК
                                    </DropdownItem>
                                    <DropdownItem
                                        key="new"
                                        description="Группировка по моделям"
                                        onClick={() => assemblyDownload(x.id)}
                                        startContent={<DownloadFileIcon width="20" height="20" className={iconClasses} />}
                                    >
                                        Сборка
                                    </DropdownItem>
                                    <DropdownItem
                                        key="copy"
                                        description="Группировка баркод/количество"
                                        onClick={() => patternDownload(x.id)}
                                        startContent={<DownloadFileIcon width="20" height="20" className={iconClasses} />}
                                    >
                                        Шаблон-Заказа WB
                                    </DropdownItem>
                                    <DropdownItem
                                        key="copy"
                                        description="Группировка баркод/количество"
                                        onClick={() => patternOzonDownload(x.id)}
                                        startContent={<DownloadFileIcon width="20" height="20" className={iconClasses} />}
                                    >
                                        Шаблон-Заказа OZON
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => shkDownload(x.id)}
                                        key="edit"
                                        description="Список шк c баркодами моделей"
                                        startContent={<DownloadFileIcon width="20" height="20" className={iconClasses} />}
                                    >
                                        ШК
                                    </DropdownItem>
                                    <DropdownItem
                                        key="edit"
                                        description="pdf файл с этикетками"
                                        onClick={() => stickerWBDownload(x.id)}
                                        startContent={<DownloadFileIcon width="20" height="20" className={iconClasses} />}
                                    >
                                        Этикетки для WB
                                    </DropdownItem>
                                    <DropdownItem
                                        key="edit"
                                        onClick={() => stickerOzonDownload(x.id)}
                                        description="pdf файл с этикетками"
                                        startContent={<DownloadFileIcon width="20" height="20" className={iconClasses} />}
                                    >
                                        Этикетки для OZON
                                    </DropdownItem>
                                </DropdownSection>
                                <DropdownSection title="Опасная зона">
                                    <DropdownItem
                                        key="delete"
                                        className="text-danger"
                                        color="danger"
                                        description=""
                                        onClick={() => deleteSupplie(x.id)}
                                        startContent={<RemoveFileIcon width="20" height="20" className={cn(iconClasses, "text-danger")} />}
                                    >
                                        Удалить заявку
                                    </DropdownItem>
                                </DropdownSection>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                );
            default:
                return (
                    <></>
                )
        }
    }, []);

    const onNextPage = useCallback(() => {
        if (page < pages) {
            setPage(page + 1);
        }
    }, [page, pages]);

    const onPreviousPage = useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
        }
    }, [page]);

    const onRowsPerPageChange = useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);

    const onSearchChange = useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = useCallback(() => {
        setFilterValue("")
        setPage(1)
    }, [])

    const topContent = useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <div className="flex justify-between gap-3 items-end">
                    <Input
                        isClearable
                        size="sm"
                        className="w-full sm:max-w-[44%]"
                        placeholder="Поиск по заявкам..."
                        startContent={<SearchIcon/>}
                        value={filterValue}
                        onClear={() => onClear()}
                        onValueChange={onSearchChange}
                    />
                    <div className="flex gap-3">
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button size="md" endContent={<ChevronDownIcon className="text-small"/>} variant="flat">
                                    Status
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={statusFilter}
                                selectionMode="multiple"
                                onSelectionChange={setStatusFilter}
                            >
                                {statusOptions.map((status) => (
                                    <DropdownItem key={status.uid} className="capitalize">
                                        {status.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button size="md" endContent={<ChevronDownIcon className="text-small"/>} variant="flat">
                                    Колонки
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={visibleColumns}
                                selectionMode="multiple"
                                onSelectionChange={setVisibleColumns}
                            >
                                {columns.map((column) => (
                                    <DropdownItem key={column.uid} className="capitalize">
                                        {column.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Button onClick={onOpen} size="md" color="success" startContent={<CrossIcon width="20" height="20"/>}>
                            <span className="text-white">Создать заявку</span>
                        </Button>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-default-400 text-small">Total data</span>
                    <label className="flex items-center text-default-400 text-small">
                        Rows per page:
                        <select
                            className="bg-transparent outline-none text-default-400 text-small"
                            onChange={onRowsPerPageChange}
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                        </select>
                    </label>
                </div>
            </div>
        );
    }, [
        filterValue,
        statusFilter,
        visibleColumns,
        onRowsPerPageChange,
        data.length,
        onSearchChange,
        hasSearchFilter,
    ]);

    const bottomContent = useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
    <span className="w-[30%] text-small text-default-400">
      {selectedKeys === "all"
          ? "All items selected"
          : ``}
    </span>
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="primary"
                    page={page}
                    total={pages}
                    onChange={setPage}
                />
                <div className="hidden sm:flex w-[30%] justify-end gap-2">
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onPreviousPage}>
                        Предыдущая
                    </Button>
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onNextPage}>
                        Следующая
                    </Button>
                </div>
            </div>
        );
    }, [selectedKeys, items.length, page, pages, hasSearchFilter]);

    const [loading, setLoading] = useState(true);
    const [shops, setShops] = useState([]);
    const [stock, setStock] = useState([]);
    const [supplie, setSupplie] = useState({});

    const createSupplie = () => {
        axios.post(`${api}/bid/save`, supplie).then(() => {
            window.location.reload();
        })
    }

    useEffect(() => {
        axios.get(`${api}/stock/list`).then(response => {
            setStock(response.data);
        })
        axios.get(`${api}/shop/list`).then(response => {
            setShops(response.data);
        }).finally(() => setLoading(false));
    }, []);

    if (loading) return <span>Loading...</span>

    return (
        <>
            <UploadShkModal isOpen={isOpenShk} onOpen={onOpenShk} onOpenChange={onOpenChangeShk} supplie={idShop}/>
            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                placement="top-center"
            >
                <ModalContent className="max-w-[350px]">
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Cоздание поставки</ModalHeader>
                            <ModalBody>
                                <Input
                                    label="Название"
                                    placeholder="Ввод названия заявки"
                                    labelPlacement="outside"
                                    className="max-w-xs"
                                    onChange={(e) => setSupplie({...supplie, name: e.target.value})}
                                />
                                <Select
                                    items={shops}
                                    onChange={(e) => setSupplie({...supplie, shop: e.target.value})}
                                    label="Магазин"
                                    placeholder="Выбор магазина"
                                    labelPlacement="outside"
                                    className="max-w-xs"
                                >
                                    {(x) => (
                                        <SelectItem key={x.id} textValue={x.name}>
                                           <span>{x.name}</span>
                                        </SelectItem>
                                    )}
                                </Select>

                                <Select
                                    items={stock}
                                    onChange={(e) => setSupplie({...supplie, stock: e.target.value})}
                                    label="Cклад"
                                    placeholder="Выбор склада"
                                    labelPlacement="outside"
                                    className="max-w-xs"
                                >
                                    {(x) => (
                                        <SelectItem key={x.id} textValue={x.name}>
                                            <span>{x.name}</span>
                                        </SelectItem>
                                    )}
                                </Select>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                     Отмена
                                </Button>
                                <Button color="success" className="text-white" onPress={createSupplie}>
                                    Создать
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
            <h3 className="text-gray-900 normal-case font-semibold tracking-tighter text-2xl m-3">Управление заявками</h3>
            <Table
                aria-label="Example table with custom cells, pagination and sorting"
                isHeaderSticky
                bottomContent={bottomContent}
                bottomContentPlacement="outside"
                selectedKeys={selectedKeys}
                topContent={topContent}
                topContentPlacement="outside"
                onSelectionChange={setSelectedKeys}
                onSortChange={setSortDescriptor}
            >
                <TableHeader columns={headerColumns}>
                    {(column) => (
                        <TableColumn
                            key={column.uid}
                            align={column.uid === "actions" ? "center" : "start"}
                            allowsSorting={column.sortable}
                        >
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody emptyContent={"No data found"} items={items}>
                    {(item) => (
                        <TableRow key={item.id}>
                            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </>
    );
}