export const formatDate = (date) => {
    const originalDate = new Date(date);

    const day = originalDate.getUTCDate().toString().padStart(2, '0');
    const month = (originalDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = originalDate.getUTCFullYear();

    return `${day}-${month}-${year}`;

}

export function checkIfThreeDaysPassed(dateString) {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    const timeDifference = currentDate - inputDate;
    const threeDaysInMillis = 3 * 24 * 60 * 60 * 1000;
    return timeDifference >= threeDaysInMillis;
}

export function calculateTimePassed(dateString) {
    // Создаем объект Date из переданной строки
    let pastDate = new Date(dateString);
    // Получаем текущую дату
    let currentDate = new Date();
    // Вычисляем разницу в миллисекундах
    let diff = currentDate - pastDate;
    // Переводим миллисекунды в минуты, часы и дни
    let minutes = Math.floor((diff / 1000) / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);
    // Возвращаем результат
    return {
        days: days,
        hours: hours % 24,
        minutes: minutes % 60
    };
}
