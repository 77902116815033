import {LogoSide} from "../../atoms/logo/logo";
import {SidebarButton} from "../../atoms/sidebarButton/sidebarButton";
import {SuppliesListIcon} from "../../../icons/supplies_lilst";
import {UpdateModelsIcon} from "../../../icons/update_models";
import {Button, useDisclosure} from "@nextui-org/react";
import {UpdateModelsModal} from "../../molecules/updateMdelsModal/updateModelsModal";
import {PSupplies, PSuppliesIcon} from "../../../icons/p_supplies";
import {ScanerIcon} from "../../../icons/scaner";
import {ModelsListIcon} from "../../../icons/models_list";
import {AnalyticIcon} from "../../../icons/analytic";
import {PlanningIcon} from "../../../icons/planning";
import {AdCompaniesIcon} from "../../../icons/adcompanies.icon";

export const Sidebar = () => {

    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    return (
        <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col content-between">
                <UpdateModelsModal isOpen={isOpen} onOpen={onOpen} onOpenChange={onOpenChange} />
                <LogoSide/>
                <div className="flex flex-col gap-6 mt-7 pl-2 pr-2">
                    <div className="flex flex-col gap-2">
                        <span className="text-gray-900 text-sm normal-case tracking-wider">Главное меню</span>
                        <SidebarButton
                            to="/"
                        >
                            <SuppliesListIcon/>
                            <span className="text-gray-900 text-1.5xl">Заявки</span>
                        </SidebarButton>
                        <SidebarButton onClick={onOpen}>
                            <UpdateModelsIcon/>
                            <span className="text-gray-900 text-1.5xl">Обновить модели</span>
                        </SidebarButton>
                        <SidebarButton to="/models">
                            <ModelsListIcon/>
                            <span className="text-gray-900 text-1.5xl">Список моделей</span>
                        </SidebarButton>
                    </div>
                </div>
                <div className="flex flex-col gap-6 mt-7 pl-2 pr-2">
                    <div className="flex flex-col gap-2">
                        <span className="text-gray-900 text-sm normal-case tracking-wider">Производство</span>
                        <SidebarButton
                            to="/production/supplies"
                        >
                            <PSuppliesIcon/>
                            <span className="text-gray-900 text-1.5xl">Заявки</span>
                        </SidebarButton>
                        <SidebarButton
                            to=""
                        >
                            <PlanningIcon/>
                            <span className="text-gray-900 text-1.5xl">Планирование</span>
                        </SidebarButton>
                    </div>
                </div>
                <div className="flex flex-col gap-6 mt-7 pl-2 pr-2">
                    <div className="flex flex-col gap-2">
                        <span className="text-gray-900 text-sm normal-case tracking-wider">Отслеживание</span>
                        <SidebarButton
                            to="/analytic"
                        >
                            <AnalyticIcon/>
                            <span className="text-gray-900 text-1.5xl">Аналитика</span>
                        </SidebarButton>
                        <SidebarButton
                            to="/scanning"
                        >
                            <ScanerIcon/>
                            <span className="text-gray-900 text-1.5xl">Сканирование</span>
                        </SidebarButton>
                    </div>
                </div>
                <div className="flex flex-col gap-6 mt-7 pl-2 pr-2">
                    <div className="flex flex-col gap-2">
                        <span className="text-gray-900 text-sm normal-case tracking-wider">Реклама</span>
                        <SidebarButton
                            to="/advert"
                        >
                            <AdCompaniesIcon/>
                            <span className="text-gray-900 text-1.5xl">Список компаний</span>
                        </SidebarButton>
                    </div>
                </div>
            </div>

            <Button color="danger" variant="flat" onPress={() => {
                localStorage.setItem("token", "false");
                window.location.reload()
            }}>Выйти</Button>
        </div>
    )
}