import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {
    Avatar,
    Button,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Pagination,
    useDisclosure
} from "@nextui-org/react";
import {CrossIcon} from "../../../icons/cross";
import {SearchIcon} from "../../../icons/search";
import {volHost} from "../../../utils/volHost";
import axios from "axios";
import {api} from "../../../utils/axios.config";
import {DeleteIcon} from "../../../icons/delete";
import {SkusIcon} from "../../../icons/skus";
import bcrypt from 'bcryptjs';

export const ProductionSupplieEdit = () => {

    const params = useParams();
    const rowsPerPage = 4;
    const [supplie, setSupplie] = useState(null);
    const [loading, setLoading] = useState(true);
    const [models, setModels] = useState([]);
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [page, setPage] = useState(1);
    const [filterValue, setFilterValue] = useState("");
    const [setupModels, setSetupModels] = useState([])
    const hasSearchFilter = Boolean(filterValue);

    const [page2, setPage2] = useState(1);

    useEffect(() => {
        axios.get(`${api}/production/bid?id=${params.id}`).then(response => {
            if(response.data.items === null){
                response.data.items = [];
            }
            setSupplie(response.data);
        }).finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (supplie !== null)
            axios.post(`${api}/production/bid/update`, supplie, { headers: {"Content-Type" : "application/json"}});
    }, [supplie]);

    const filteredItems = useMemo(() => {
        let filteredUsers = [...models];

        if (hasSearchFilter) {
            filteredUsers = filteredUsers.filter((user) =>
                user.name.toString().toLowerCase().includes(filterValue.toLowerCase())
                || user.barcode?.includes(filterValue.toLowerCase())
                || user.artcode?.includes(filterValue.toLowerCase())
                || user.artcodeWB?.includes(filterValue.toLowerCase())
                || user.artcodeOZON?.includes(filterValue.toLowerCase())
            );
        }

        return filteredUsers;
    }, [models, filterValue]);

    const filteredItems2 = useMemo(() => {

        let filteredUsers = [];
        try {
            filteredUsers = [...supplie.items];
        } catch (e) {};
        return filteredUsers;

    }, [supplie]);

    const pages = Math.ceil( filteredItems2.length / 6);

    const items2 = useMemo(() => {
        const rowsPerPageLocal = 6;
        const start = (page2 - 1) * rowsPerPageLocal;
        const end = start + rowsPerPageLocal;
        return filteredItems2.slice(start, end);
    }, [page2, supplie]);

    const onNextPage = useCallback(() => {
        if (page2 < pages) {
            setPage2(page2 + 1);
        }
    }, [page2, pages]);

    const onPreviousPage = useCallback(() => {
        if (page2 > 1) {
            setPage2(page2 - 1);
        }
    }, [page2]);


    const deleteModel = (sku) => {
        const items = [...supplie.items];
        const itemsFiltered = items.filter(x => x.trackNumber !== sku)
        setSupplie({ ...supplie, items: itemsFiltered })
    }

    const items = useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);

    const openModal = () => {
        setSetupModels([]);
        axios.get(`${api}/model?shop=${supplie.shop.id}`).then(response => {
            setModels(response.data);
        }).finally(() => {
            onOpen();
        })
    }

    const onSearchChange = useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = useCallback(()=>{
        setFilterValue("")
        setPage(1)
    },[])


    function generate13DigitNumber() {
        let result = '';
        for (let i = 0; i < 13; i++) {
            result += Math.floor(Math.random() * 10);
        }
        return result;
    }

    const addMode = () => {
        setSupplie((prevSupplie) => {
            const newItems = [...setupModels];
            let items = [...prevSupplie.items];

            newItems.forEach(x => {
                for (let i = 0; i < x.amount; i++) {
                    let hash;
                    do {
                        hash = supplie.id.slice(supplie.id.length - 2, supplie.id.length) + String(generate13DigitNumber());
                    } while (items.some(item => item.trackNumber === hash));

                    items.push({
                        model: x.model,
                        trackNumber: hash
                    });
                }
            });

            return { ...prevSupplie, items: items };
        });
        onOpenChange();
    };

    const setupModelsInput = (model, amount) => {
        setSetupModels(prevModels => {
            let tempModelsFiltered = [];
            if (amount !== "") {
                tempModelsFiltered = prevModels.filter(x => x.model.barcode !== model.barcode);
                tempModelsFiltered.push({
                    amount: Number(amount),
                    model: model
                });
            } else {
                tempModelsFiltered = prevModels.filter(x => x.model.barcode !== model.barcode);
            }
            return tempModelsFiltered;
        });
    };

    if(loading) return "Loading..."

    return (
        <>
            <h3 className="text-gray-900 normal-case font-semibold tracking-tighter text-2xl m-3">
                Редактирование заявки производства <span className="text-secondary">{params.id}</span></h3>
            <Button size="md" onPress={openModal} color="success" startContent={<CrossIcon width="20" height="20"/>}>
                <span className="text-white">Добавить модель</span>
            </Button>
            <Button className="ml-5" size="md" onPress={() => setSupplie({...supplie, items : []})} color="danger" startContent={<DeleteIcon width="20" height="20"/>}>
                <span className="text-white">Удалить все</span>
            </Button>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="4xl">
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Добавить модели</ModalHeader>
                            <ModalBody >
                                <Input
                                    isClearable
                                    size="sm"
                                    className="w-full"
                                    placeholder="Поиск по моделям..."
                                    startContent={<SearchIcon/>}
                                    value={filterValue}
                                    onClear={onClear}
                                    onValueChange={onSearchChange}
                                />
                                <div className="flex flex-col gap-5 overflow-y-auto max-h-[300px]">
                                    {items.map((x, i) => {
                                        return (
                                            <div key={i} className="flex justify-between">
                                                <div className="flex gap-2">
                                                    <Avatar radius="sm" className="w-[50px] h-[50px]" src={`https:${volHost(x?.artcodeWB)}/images/big/1.webp`} />
                                                    <div className="flex flex-col">
                                                        <span className="font-bold">{x.name} · {x.color}</span>
                                                        <div className="flex gap-2">
                                                            <span className="text-warning">КИЗ · {x.barcode}</span>
                                                            <span className="text-secondary">АРТ · {x.artcode}</span>
                                                            <span className="text-cyan-600">РАЗМЕР · {x.size}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Input  value={setupModels.filter(z => z.model.barcode === x.barcode)[0]?.amount || ""}
                                                        key={i} onChange={(e) => setupModelsInput(x, e.target.value)} className="max-w-[70px]" placeholder="0 шт."/>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="w-full flex justify-center">
                                    <Pagination
                                        isCompact
                                        showControls
                                        showShadow
                                        color="primary"
                                        page={page}
                                        total={Math.ceil(filteredItems.length / rowsPerPage)}
                                        onChange={setPage}
                                    />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Закрыть
                                </Button>
                                <Button color="primary" onPress={addMode}>
                                    Сохранить
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
            <div className="flex flex-col gap-5 pt-5">
                {items2.map((b, j) => {
                    return (
                        <div className="p-4 z-0 flex flex-col relative justify-between gap-4 bg-content1 overflow-auto rounded-large shadow-small w-full max-h-[382px]">
                            <div key={j} className="flex justify-between">
                            <div className="flex gap-2 items-center">
                                <Avatar radius="sm" className="w-[65px] h-[65px]" src={`https:${volHost(b.model?.artcodeWB)}/images/big/1.jpg`} />
                                <div className="flex flex-col">
                                    <span className="font-bold">{b.model?.name} · {b.model?.color} <span className="flex gap-2 items-center"> <SkusIcon/> {b.trackNumber}</span> </span>
                                    <div className="flex gap-2">
                                        <span className="text-warning">КИЗ · {b.model?.barcode}</span>
                                        <span className="text-secondary">АРТ · {b.model?.artcode}</span>
                                        <span className="text-cyan-600">РАЗМЕР · {b.model?.size}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-3 items-center">
                                <Button onPress={() => deleteModel(b.trackNumber)} className="min-h-[40px] " color="danger" startContent={<DeleteIcon width="20px" height="20px"/>}></Button>
                            </div>
                        </div>
                        </div>
                    )
                })}
            </div>
            <div className="py-2 px-2 flex justify-between items-center">
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="primary"
                    page={page2}
                    total={pages}
                    onChange={setPage2}
                />
                <div className="hidden sm:flex w-[30%] justify-end gap-2">
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onPreviousPage}>
                        Предыдущая
                    </Button>
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onNextPage}>
                        Следующая
                    </Button>
                </div>
            </div>
        </>
    )
}