import {useParams} from "react-router-dom";
import {useCallback, useEffect, useMemo, useState} from "react";
import axios from "axios";
import {api} from "../../../utils/axios.config";
import {
    Button,
    Chip, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Input,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow, Tooltip
} from "@nextui-org/react";
import {checkIfThreeDaysPassed, formatDate} from "../../../utils/formatDate";
import {SearchIcon} from "../../../icons/search";
import {ChevronDownIcon} from "../../../icons/chevron_down";

const columns = [
    {name: "ДАТА", uid: "date"},
    {name: "НОМЕР", uid: "number"},
    {name: "ЗАЯВКА", uid: "bidId"},
    {name: "НАЗВАНИЕ", uid: "name"},
    {name: "БАРКОД", uid: "barcode"},
    {name: "АРТ", uid: "artcode", },
    {name: "АРТ-ВБ", uid: "artcodeWB"},
    {name: "АРТ-ОЗОН", uid: "artcodeOZON"},
    {name: "РАЗМЕР", uid: "size"},
    {name: "ЦВЕТ", uid: "color"},
];

export const StagePage = () => {

    const [data, setData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [filterValue, setFilterValue] = useState("");

    const params = useParams();


    useEffect(() => {
        axios.get(`${api}/tracking?stage=${params.name}`)
            .then(response => {
                setData(response.data)
            })
            .finally(() => setLoading(false))
    }, [params])



    const hasSearchFilter = Boolean(filterValue);

    const filteredItems = useMemo(() => {
        let filteredUsers = [...data];

        if (hasSearchFilter) {
            filteredUsers = filteredUsers.filter((user) =>
                user.item.model.name?.toLowerCase().includes(filterValue.toLowerCase()) || user.item.model.id?.toLowerCase().includes(filterValue.toLowerCase())
                || user.item.model.barcode?.toLowerCase().includes(filterValue.toLowerCase()) || user.item.model.artcode?.includes(filterValue) ||
                user.item.trackNumber?.toLowerCase().includes(filterValue.toLowerCase())
            );
        }

        return filteredUsers;
    }, [data, filterValue]);

    const sortedItems = useMemo(() => {
        return [...filteredItems].sort((a, b) => {
            const first = a.stages[a.stages.length - 1].date;
            const second = b.stages[b.stages.length - 1].date;
            const cmp = new Date(first).getTime() < new Date(second).getTime() ? -1 : new Date(first).getTime() > new Date(second).getTime() ? 1 : 0;
            return -cmp;
        });

    }, [filteredItems]);

    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const items = useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [data, page, rowsPerPage, filteredItems]);

    const onNextPage = useCallback(() => {
        if (page < pages) {
            setPage(page + 1);
        }
    }, [page, pages]);

    const onPreviousPage = useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
        }
    }, [page]);

    const onRowsPerPageChange = useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);


    const bottomContent = useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
                <span className="w-[30%] text-small text-default-400"></span>
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="primary"
                    page={page}
                    total={pages}
                    onChange={setPage}
                />
                <div className="hidden sm:flex w-[30%] justify-end gap-2">
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onPreviousPage}>
                        Предыдущая
                    </Button>
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onNextPage}>
                        Следующая
                    </Button>
                </div>
            </div>
        );
    }, [items.length, page, pages]);

    const renderCells = (row, index) => {
            return (
                <TableRow className={checkIfThreeDaysPassed(row.stages.filter(x => x.value === params.name)[0].date) ? "bg-danger rounded-2xl" : ""} key={index}>
                    <TableCell>{formatDate(row.stages.filter(x => x.value === params.name)[0].date)}</TableCell>
                    <TableCell>{row.item.trackNumber}</TableCell>
                    <TableCell>{row.bidId}</TableCell>
                    <TableCell>{row.item.model.name}</TableCell>
                    <TableCell>{row.item.model.barcode}</TableCell>
                    <TableCell>{row.item.model.artcode}</TableCell>
                    <TableCell>{row.item.model.artcodeWB}</TableCell>
                    <TableCell>{row.item.model.artcodeOZON}</TableCell>
                    <TableCell>{row.item.model.size}</TableCell>
                    <TableCell>{row.item.model.color}</TableCell>
                </TableRow>
            )
    }

    const onSearchChange = useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const topContent = useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <div className="flex justify-between gap-3 items-end">
                    <Input
                        isClearable
                        size="sm"
                        className="w-full sm:max-w-[44%] pt-[-4px]"
                        placeholder="Поиск по моделям..."
                        startContent={<SearchIcon/>}
                        value={filterValue}
                        onValueChange={onSearchChange}
                    />
                </div>
            </div>
        );
    }, [
        filterValue,
        onRowsPerPageChange,
        items.length,
        onSearchChange,
    ]);

    if (loading) return "Loading..."

    return (
        <>
            <h3 className="text-gray-900 normal-case font-semibold tracking-tighter text-2xl m-3">Список моделей в работе</h3>
            <p className="text-gray-500 normal-case font-semibold tracking-tighter m-3">Общее количество {data.length}</p>
            <Table aria-label="Таблица сканов" className="mt-10"
                   bottomContent={bottomContent}
                   bottomContentPlacement="outside"
                   topContent={topContent}
                   topContentPlacement="outside"
            >
                <TableHeader>
                    {columns.map((column, index) =>
                        <TableColumn key={index}>{column.name}</TableColumn>
                    )}
                </TableHeader>
                <TableBody emptyContent={"Ожидание сканирования"}>
                    {items.map((row, index) =>
                        renderCells(row, index)
                    )}
                </TableBody>
            </Table>
        </>
    )
}