export const PSuppliesIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 9.75C21.59 9.75 21.25 9.41 21.25 9V7C21.25 4.42 19.58 2.75 17 2.75H7C4.42 2.75 2.75 4.42 2.75 7V9C2.75 9.41 2.41 9.75 2 9.75C1.59 9.75 1.25 9.41 1.25 9V7C1.25 3.56 3.56 1.25 7 1.25H17C20.44 1.25 22.75 3.56 22.75 7V9C22.75 9.41 22.41 9.75 22 9.75Z" fill="white"/>
            <path d="M17 22.75H7C3.56 22.75 1.25 20.44 1.25 17V15C1.25 14.59 1.59 14.25 2 14.25C2.41 14.25 2.75 14.59 2.75 15V17C2.75 19.58 4.42 21.25 7 21.25H17C19.58 21.25 21.25 19.58 21.25 17V15C21.25 14.59 21.59 14.25 22 14.25C22.41 14.25 22.75 14.59 22.75 15V17C22.75 20.44 20.44 22.75 17 22.75Z" fill="white"/>
            <path d="M16.4002 8.21094L12.6402 6.18094C12.2402 5.97094 11.7702 5.97094 11.3702 6.18094L7.60016 8.21094C7.33016 8.36094 7.16016 8.65094 7.16016 8.97094C7.16016 9.30094 7.33016 9.59094 7.60016 9.74094L11.3602 11.7709C11.5602 11.8809 11.7802 11.9309 12.0002 11.9309C12.2202 11.9309 12.4402 11.8809 12.6402 11.7709L16.4002 9.74094C16.6702 9.59094 16.8402 9.30094 16.8402 8.97094C16.8402 8.65094 16.6702 8.36094 16.4002 8.21094Z" fill="white"/>
            <path d="M10.74 12.4685L7.24 10.7185C6.97 10.5885 6.66 10.5985 6.4 10.7585C6.15 10.9185 6 11.1885 6 11.4885V14.7985C6 15.3685 6.32 15.8885 6.83 16.1385L10.33 17.8885C10.45 17.9485 10.58 17.9785 10.72 17.9785C10.88 17.9785 11.03 17.9385 11.17 17.8485C11.43 17.6885 11.58 17.4185 11.58 17.1185V13.8085C11.57 13.2385 11.26 12.7285 10.74 12.4685Z" fill="white"/>
            <path d="M17.5919 10.7585C17.3319 10.5985 17.0219 10.5885 16.7519 10.7185L13.2519 12.4685C12.7419 12.7285 12.4219 13.2385 12.4219 13.8085V17.1185C12.4219 17.4185 12.5719 17.6885 12.8319 17.8485C12.9719 17.9385 13.1219 17.9785 13.2819 17.9785C13.4119 17.9785 13.5419 17.9485 13.6719 17.8885L17.1719 16.1385C17.6819 15.8785 18.0019 15.3685 18.0019 14.7985V11.4885C18.0019 11.1885 17.8519 10.9185 17.5919 10.7585Z" fill="white"/>
        </svg>
    )
}