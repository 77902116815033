import {useCallback, useEffect, useMemo, useState} from "react";
import axios from "axios";
import {api} from "../../../utils/axios.config";
import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Button,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Chip,
    User,
    Autocomplete,
    AutocompleteItem,
    Pagination, useDisclosure,
} from "@nextui-org/react";

import {SearchIcon} from "../../../icons/search";
import {ChevronDownIcon} from "../../../icons/chevron_down";
import {volHost} from "../../../utils/volHost";
import {EditIcon} from "../../../icons/edit";
import {CrossIcon} from "../../../icons/cross";
import {SettingsPlanningModal} from "../../molecules/settingsPlanningModal/settingsPlanningModal";

const INITIAL_VISIBLE_COLUMNS = ["model", "stock", "inWayToClient", "inWayFromClient", "quantity", "quantityFull", "supplie", "minQuantity", "quantityInProduction"];

const columns = [
    {name: "МОДЕЛЬ", uid: "model"},
    {name: "СКЛАД", uid: "stock"},
    {name: "К КЛИЕНТУ", uid: "inWayToClient"},
    {name: "ОТ КЛИЕНТА", uid: "inWayFromClient"},
    {name: "ДОСТУПНО", uid: "quantity"},
    {name: "ВСЕГО", uid: "quantityFull"},
    {name: "В ПОСТАВКЕ", uid: "supplie"},
    {name: "В ПРОИЗВОДСТВЕ", uid: "quantityInProduction"},
    {name: "НЕОБХОДИМО", uid: "minQuantity"},
];

export const PlanningPage = () => {

    const [shops, setShop] = useState(['658b264ccedd5c1b705cad8f'])
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const [filterValue, setFilterValue] = useState("");
    const [visibleColumns, setVisibleColumns] = useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [statusFilter, setStatusFilter] = useState("all");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [warehouse, setWarehouse] = useState()
    const [edit, setEdit] = useState("65b8158d2c998646dca917ea");
    const [update, setUpdate] = useState(false);
    const {isOpen, onOpen, onOpenChange} = useDisclosure();


    useEffect(() => {
        axios.get(`${api}/leftovers?shop=${shops[0]}`)
            .then(response => {
                setData(response.data)
            })
            .finally(() => setLoading(false));
    }, [update])

    let uniqueWarehouses = [...new Set(data.map(item => item.warehouseName))];

    const [page, setPage] = useState(1);

    const hasSearchFilter = Boolean(filterValue);

    const headerColumns = useMemo(() => {
        if (visibleColumns === "all") return columns;

        return columns.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);

    const filteredItems = useMemo(() => {
        let filteredUsers = [...data];

        if (hasSearchFilter) {
            filteredUsers = filteredUsers.filter((user) =>
                user.model.barcode.includes(filterValue.toLowerCase()) || user.model.name?.toLowerCase().includes(filterValue.toLowerCase()),
            );
        }

        return filteredUsers;
    }, [data, filterValue]);

    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const onRowsPerPageChange = useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);

    const onNextPage = useCallback(() => {
        if (page < pages) {
            setPage(page + 1);
        }
    }, [page, pages]);

    const onPreviousPage = useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
        }
    }, [page]);


    const onSearchChange = useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = useCallback(()=>{
        setFilterValue("")
        setPage(1)
    },[])

    const items = useMemo(() => {
        const filteredItemsSorted = filteredItems.sort((a, b) => a.model.name === b.model.name);
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItemsSorted.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);

    const topContent = useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <div className="flex justify-between gap-3 items-end">
                    <Input
                        isClearable
                        size="sm"
                        className="sm w-full sm:max-w-[44%]"
                        placeholder="Поиск..."
                        startContent={<SearchIcon />}
                        value={filterValue}
                        onClear={() => onClear()}
                        onValueChange={onSearchChange}
                    />
                    <div className="flex gap-3">
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<ChevronDownIcon className="text-small" />} variant="flat">
                                    Склад
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={warehouse}
                                selectionMode="multiple"
                                onSelectionChange={setWarehouse()}
                            >
                                {uniqueWarehouses.map((column, index) => (
                                    <DropdownItem key={index} className="capitalize">
                                        {column}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>

                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<ChevronDownIcon className="text-small" />} variant="flat">
                                    Колонки
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={visibleColumns}
                                selectionMode="multiple"
                                onSelectionChange={setVisibleColumns}
                            >
                                {columns.map((column) => (
                                    <DropdownItem key={column.uid} className="capitalize">
                                        {column.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>

                        <Button color="success" className="text-white" endContent={<CrossIcon width={20} height={20} />}>
                            Заявка
                        </Button>
                        <Button color="primary" size="md">
                             Настройка
                        </Button>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-default-400 text-small">Всего {data.length} моделей</span>
                    <label className="flex items-center text-default-400 text-small">
                        Количество строк на страницу:
                        <select
                            className="bg-transparent outline-none text-default-400 text-small"
                            onChange={onRowsPerPageChange}
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                    </label>
                </div>
            </div>
        );
    }, [
        warehouse,
        filterValue,
        statusFilter,
        visibleColumns,
        onRowsPerPageChange,
        data.length,
        onSearchChange,
        hasSearchFilter,
    ]);

    const updateMinQuantity = (id, value) => {
        let valueCheck = parseInt(value);
        if (!isNaN(valueCheck) && valueCheck !== NaN) {
            const body = {
                "id": id,
                "value": valueCheck
            };
            axios.post(`${api}/leftovers/update/minQuantity`, body)
                .then(() => setUpdate(!update))
        }
    }

    const renderCell = useCallback((element, columnKey) => {
        const cellValue = element[columnKey];

        switch (columnKey) {
            case "model":
                return (
                    <User
                        avatarProps={{radius: "lg", src: `https:${volHost(element.model.artcodeWB)}/images/big/1.webp`}}
                        description={element.model.barcode}
                        name={element.model.name + "  -  " + element.model.size}
                    >
                    </User>
                );
            case "stock":
                return (
                  <p>{element.warehouseName}</p>
                );
            case "minQuantity":
                return (
                    <>
                        <div className="flex gap-3">
                           <Input size="sm" className="max-w-[100px]" placeholder={element.minQuantity} onChange={(e) => updateMinQuantity(element.id, e.target.value)}/>
                        </div>
                    </>
                );
            case "supplie":
                return (
                  <>0</>
                );
            default:
                return cellValue;
        }
    }, [update]);

    const bottomContent = useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
                <span className="w-[30%] text-small text-default-400"></span>
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="primary"
                    page={page}
                    total={pages}
                    onChange={setPage}
                />
                <div className="hidden sm:flex w-[30%] justify-end gap-2">
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onPreviousPage}>
                        Предыдущая
                    </Button>
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onNextPage}>
                        Следующая
                    </Button>
                </div>
            </div>
        );
    }, [items.length, page, pages, hasSearchFilter]);

    if(loading) return "Loading..."

    return (
     <>
         <SettingsPlanningModal onOpenChange={onOpenChange} isOpen={isOpen}/>
         <Table
             isHeaderSticky
             bottomContentPlacement="outside"
             bottomContent={bottomContent}
             topContent={topContent}
             topContentPlacement="outside"
         >
             <TableHeader columns={headerColumns}>
                 {(column) => (
                     <TableColumn
                         key={column.uid}
                         align={column.uid === "actions" ? "center" : "start"}
                         allowsSorting={column.sortable}
                     >
                         {column.name}
                     </TableColumn>
                 )}
             </TableHeader>
             <TableBody emptyContent={"No data found"} items={items}>
                 {(item) => (
                     <TableRow key={item.id}>
                         {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                     </TableRow>
                 )}
             </TableBody>
         </Table>
     </>
    );
}
