import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    Timeline,
    TimelineItem,
    TimelineConnector,
    TimelineIcon,
    Typography,
    TimelineHeader, TimelineBody,
} from "@material-tailwind/react";
import {
    BellIcon,
    ArchiveBoxIcon,
    CurrencyDollarIcon,
} from "@heroicons/react/24/solid";
import {HomeIcon} from "@heroicons/react/16/solid";
import {Button, Card, CardBody, CardHeader, Code, Divider, Image, Tooltip, User} from "@nextui-org/react";
import {volHost} from "../../../utils/volHost";
import axios from "axios";
import {api} from "../../../utils/axios.config";
import {calculateTimePassed, checkIfThreeDaysPassed, formatDate} from "../../../utils/formatDate";
import {types} from "../scanning/scanning";

export const TimeLineStagesPage = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`${api}/tracking/supplie?id=${parmas.id}`)
            .then(response => setData(response.data))
            .finally(() => setLoading(false));
    }, []);

    const parmas = useParams();
    function Grid({ children }) {
        return <div className="flex flex-wrap gap-3">{children}</div>;
    }

    function GridItem({ children }) {
        return <div className="w-[24%]">{children}</div>;
    }

    if(loading) return "Loading..."


    return (
        <>
            <h3 className="text-gray-900 normal-case font-semibold tracking-tighter text-2xl m-3">Статус моделей заявки {parmas.id}</h3>
            <Grid>
                {data.sort((a, b) => a.stages.length - b.stages.length).map(x => {
                    return (
                        <GridItem>
                            <Card>
                                <CardHeader className="flex justify-between">
                                    <User
                                        avatarProps={{radius: "lg", src: "https:" + volHost(x.item.model.artcodeWB) + "/images/big/1.webp"}}
                                        description={x.item.trackNumber}
                                        name={x.item.model.name}
                                    >
                                        123
                                    </User>
                                    <div className="flex flex-col">
                                        <p className="text-small text-inherit">{x.item.model.size}</p>
                                        <p className="text-tiny text-foreground-400">{x.item.model.barcode}</p>
                                    </div>
                                </CardHeader>
                                <Divider/>
                                <CardBody>
                                    <Timeline>
                                        {x.stages?.map((s, index) => {
                                            return (
                                                <TimelineItem>
                                                    <TimelineConnector />
                                                    <TimelineHeader>
                                                        <TimelineIcon className={checkIfThreeDaysPassed(s.date) && x.stages.length === index + 1 && s.value !== "DISPATCHED" ? "p-2 bg-danger" : "p-2 bg-success"}>
                                                        </TimelineIcon>
                                                        <p className='text-white font-bold'>
                                                            {types.filter(t => t.xvalue === s.value)[0].value}
                                                        </p>
                                                        {checkIfThreeDaysPassed(s.date) && x.stages.length === index + 1 && s.value !== "DISPATCHED" ? (
                                                            <>
                                                                {(() => {
                                                                    let timePassed = calculateTimePassed(s.date);
                                                                    return (
                                                                        <>
                                                                            <Tooltip content={`Прошло ${timePassed.days} дней, ${timePassed.hours} часов и ${timePassed.minutes} минут.`}>
                                                                                <Button size="sm" color="danger">Задерживается</Button>
                                                                            </Tooltip>
                                                                        </>
                                                                    );
                                                                })()}
                                                            </>
                                                        ) : <></>}
                                                    </TimelineHeader>
                                                    <TimelineBody className="pb-8">
                                                        Принят в обработку <Code>{formatDate(s.date)}</Code>
                                                        <br/>
                                                    </TimelineBody>
                                                </TimelineItem>
                                            )
                                        })}
                                    </Timeline>
                                </CardBody>
                            </Card>
                        </GridItem>
                    )
                })}
            </Grid>
        </>
    )
}