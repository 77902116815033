import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {NextUIProvider} from "@nextui-org/react";
import {ThemeProvider as NextThemesProvider} from "next-themes";
import {MantineProvider} from "@mantine/core";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <NextUIProvider>
              <NextThemesProvider attribute="class" defaultTheme="dark">
                    <App />
              </NextThemesProvider>
          </NextUIProvider>
      </BrowserRouter>
  </React.StrictMode>
);