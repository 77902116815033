import {
    Button, CircularProgress,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Select,
    SelectItem
} from "@nextui-org/react";
import {Dropzone, IMAGE_MIME_TYPE, MIME_TYPES} from '@mantine/dropzone';
import {DropZone} from "../../organisms/dropzone/dropzone";
import {useEffect, useState} from "react";
import axios from "axios";
import {api} from "../../../utils/axios.config";
import {LoaderOverlay} from "../../atoms/loaderOverlay/loaderOverlay";
import templateXLSX from "../../../utils/template/tempate.xlsx";
export const UpdateModelsModal = ({isOpen, onOpen, onOpenChange}) => {

    const [data, setData] = useState();
    const [shops, setShops] = useState();
    const [shop, setShop] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`${api}/shop/list`).then(response => {
            setShops(response.data);
        }).finally(() => setLoading(false));
    }, [])

    const handleUpload = () => {
        let elements = [];
        setLoading(true)
        for (let i in data) {
            if (data[i] === []) break;
            if (data[i][0] === undefined) break;
            if (Number(i) !== 0) {
                elements.push(
                    {
                        "name": data[i][0],
                        "barcode": data[i][1]?.toString().replaceAll(" ", ""),
                        "size": data[i][2],
                        "color": data[i][3],
                        "artcode": data[i][4],
                        "artcodeWB": data[i][5],
                        "artcodeOZON": data[i][6]?.toString().replaceAll("\r", ""),
                        "material": data[i][7],
                        "embossing": data[i][8],
                        "sole": data[i][9],
                        "print": data[i][10],
                    }
                )
            }
        }
        axios.post(`${api}/model/update?shop=${shop}`, elements).then(() => {
        })
            .finally(() => {
                setLoading(false);
                onOpenChange();
            });
    }

    return (
        <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            placement="top-center"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        {loading ? <LoaderOverlay/> : ""}
                        <ModalHeader className="flex flex-col gap-1">Загрузка моделей</ModalHeader>
                        <ModalBody>
                            <Select
                                items={shops}
                                onChange={(e) => setShop( e.target.value)}
                                label="Магазин"
                                placeholder="Выбор магазина"
                                labelPlacement="outside"
                                className="w-full"
                            >
                                {(x) => (
                                    <SelectItem key={x.id} textValue={x.name}>
                                        <span>{x.name}</span>
                                    </SelectItem>
                                )}
                            </Select>
                            <DropZone data={data} setData={setData} />
                        </ModalBody>
                        <ModalFooter className="justify-between">
                            <div>
                                <a className="z-0 group relative inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap font-normal subpixel-antialiased overflow-hidden tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 px-unit-4 min-w-unit-20 h-unit-10 text-small gap-unit-2 rounded-medium [&>svg]:max-w-[theme(spacing.unit-8)] data-[pressed=true]:scale-[0.97] transition-transform-colors-opacity motion-reduce:transition-none bg-primary text-primary-foreground data-[hover=true]:opacity-hover"
                                   href={templateXLSX} download="шаблон моделей.xlsx">
                                    Шаблон
                                </a>
                            </div>
                            <div className="flex gap-2">
                                <Button color="danger" variant="flat" onPress={onClose}>
                                    Отмена
                                </Button>
                                <Button color="success" onPress={handleUpload}>
                                    <span className="text-white">Сохранить</span>
                                </Button>
                            </div>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    )
}