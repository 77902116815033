import {AreaChartStage} from "../../molecules/areaChartStage/areaChartStage";
import {BarChartStage} from "../../molecules/barChartStage/barChartStage";
import {AreaBottomChartStage} from "../../molecules/areaBottomChartStage/areaBottomChartStage";
import {types} from "../scanning/scanning";

export const AnalyticPage = () => {

    return (
         <>
             <h3 className="text-gray-900 normal-case font-semibold tracking-tighter text-2xl m-3">Аналитика</h3>
             <div className="grid grid-cols-4 gap-4">
                 <div>
                     <AreaChartStage stage={types[0].xvalue} name={types[0].value} color="#6576FF"/>
                 </div>
                 <div>
                     <AreaChartStage stage={types[1].xvalue} name={types[1].value} color="#816BFF"/>
                 </div>
                 <div>
                     <AreaChartStage stage={types[2].xvalue} name={types[2].value} color="#FF63A5"/>
                 </div>
                 <div>
                     <AreaChartStage stage={types[3].xvalue} name={types[3].value} color="#F4BD0E"/>
                 </div>
             </div>
             <div className="grid grid-cols-4 gap-4 mt-4">
                 <div className="col-span-3">
                    <BarChartStage/>
                 </div>
                 <div className="flex flex-col gap-4">
                    <AreaBottomChartStage stage={types[4].xvalue} name={types[4].value} color="#09C2DE"/>
                    <AreaBottomChartStage stage={types[5].xvalue} name={types[5].value} color="#1EE0AC"/>
                 </div>
             </div>

         </>
     )
}