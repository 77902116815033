export const StatIcon = (props) => {
    return (
        <svg {...props} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="7" fill="#F4F7FE"/>
            <g clip-path="url(#clip0_861_209)">
                <path d="M9.4 12.2H9.6C10.37 12.2 11 12.83 11 13.6V20.6C11 21.37 10.37 22 9.6 22H9.4C8.63 22 8 21.37 8 20.6V13.6C8 12.83 8.63 12.2 9.4 12.2ZM15 8C15.77 8 16.4 8.63 16.4 9.4V20.6C16.4 21.37 15.77 22 15 22C14.23 22 13.6 21.37 13.6 20.6V9.4C13.6 8.63 14.23 8 15 8ZM20.6 16C21.37 16 22 16.63 22 17.4V20.6C22 21.37 21.37 22 20.6 22C19.83 22 19.2 21.37 19.2 20.6V17.4C19.2 16.63 19.83 16 20.6 16Z" fill="#27272A"/>
            </g>
            <defs>
                <clipPath id="clip0_861_209">
                    <rect width="24" height="24" fill="white" transform="translate(3 3)"/>
                </clipPath>
            </defs>
        </svg>
    )
}