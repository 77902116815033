import {useParams} from "react-router-dom";
import axios from "axios";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {api} from "../../../utils/axios.config";
import {
    Accordion,
    AccordionItem,
    Avatar,
    Button,
    Divider,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Pagination,
    useDisclosure
} from "@nextui-org/react";
import {CrossIcon} from "../../../icons/cross";
import {SkusIcon} from "../../../icons/skus";
import {SearchIcon} from "../../../icons/search";
import {DeleteIcon} from "../../../icons/delete";
import {volHost} from "../../../utils/volHost";

export const SupplieEditPage = () => {

    const [supplie, setSupplie] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentBox, setCurrentBox] = useState();
    const [models, setModels] = useState([]);
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [page, setPage] = useState(1);
    const [filterValue, setFilterValue] = useState("");
    const [setupModels, setSetupModels] = useState([])
    const hasSearchFilter = Boolean(filterValue);

    const params = useParams();
    const rowsPerPage = 4;

    useEffect(() => {
        axios.get(`${api}/bid/${params.id}`).then(response => {
            if(response.data.boxes === null){
                response.data.boxes = [];
            }
            setSupplie(response.data);
        }).finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        if (supplie !== null)
            axios.post(`${api}/bid/update`, supplie, { headers: {"Content-Type" : "application/json"}});
    }, [supplie]);

    const filteredItems = useMemo(() => {
        let filteredUsers = [...models];

        if (hasSearchFilter) {
            filteredUsers = filteredUsers.filter((user) =>
                user.name.toString().toLowerCase().includes(filterValue.toLowerCase())
                || user.barcode?.includes(filterValue.toLowerCase())
                || user.artcode?.includes(filterValue.toLowerCase())
                || user.artcodeWB?.includes(filterValue.toLowerCase())
                || user.artcodeOZON?.includes(filterValue.toLowerCase())
            );
        }

        return filteredUsers;
    }, [models, filterValue]);

    const items = useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);

    const createBox = () => {
        const box = {
            "skus" : "",
            "items": []
        }
        const boxes = supplie.boxes;
        boxes.push(box);
        setSupplie({...supplie, boxes: boxes});
    }

    const openModal = (boxIndex) => {
        setCurrentBox(boxIndex);
        setSetupModels([]);
        axios.get(`${api}/model?shop=${supplie.shop.id}`).then(response => {
            setModels(response.data);
        }).finally(() => {
            onOpen();
        })
    }

    const onSearchChange = useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = useCallback(()=>{
        setFilterValue("")
        setPage(1)
    },[])


    const addModelInBox = () => {
        setSupplie((prevSupplie) => {
            const boxesTemp = [...prevSupplie.boxes];
            const box = { ...boxesTemp[currentBox] };
            const items = [...box.items, ...setupModels];
            const mergedItems = items.reduce((acc, currentItem) => {
                const existingItem = acc.find((item) => item.model.barcode === currentItem.model.barcode);
                if (existingItem) {
                    existingItem.amount += currentItem.amount;
                } else {
                    acc.push(currentItem);
                }
                return acc;
            }, []);
            box.items = mergedItems;
            boxesTemp[currentBox] = box;
            return { ...prevSupplie, boxes: boxesTemp };
        });

        onOpenChange();
    };

    const deleteModel = (sku, indexBox) => {
        const boxesTemp = [...supplie.boxes];
        const box = {...boxesTemp[indexBox]};
        box.items = box.items.filter(x => x.model.barcode !== sku)
        boxesTemp[currentBox] = box;
        setSupplie({ ...supplie, boxes: boxesTemp })
    }

    const setupModelsInput = (model, amount) => {
        setSetupModels(prevModels => {
            let tempModelsFiltered = [];
            if (amount !== "") {
                tempModelsFiltered = prevModels.filter(x => x.model.barcode !== model.barcode);
                tempModelsFiltered.push({
                    amount: Number(amount),
                    model: model
                });
            } else {
                tempModelsFiltered = prevModels.filter(x => x.model.barcode !== model.barcode);
            }
            return tempModelsFiltered;
        });
    };


    if(loading) return <span>Loading...</span>

    return (
        <>
            <h3 className="text-gray-900 normal-case font-semibold tracking-tighter text-2xl m-3">
                Редактирование заявки <span className="text-warning">{params.id}</span></h3>
            <Button size="md" color="success" onPress={createBox} startContent={<CrossIcon width="20" height="20"/>}>
                <span className="text-white">Добавить короб</span>
            </Button>
            <div className="flex gap-1 pt-5">
                <Accordion variant="splitted">
                    {supplie.boxes.map((x, i) => {
                        return (
                            <AccordionItem className="pb-5" key={i} title={
                                <div className="flex gap-3">
                                    <span>Короб-{i + 1}</span>
                                    <span>{x.items.reduce((sum, item) => sum + Number(item.amount), 0)} шт.</span>
                                </div>
                            } subtitle={
                                <div className="flex gap-3 items-center">
                                    <SkusIcon/>
                                    <span>{x.skus ? x.skus : "ШК не задан"}</span>
                                </div>
                            }>
                                <Divider className="mb-5"/>
                                <div className="flex justify-end">
                                    <Button color="secondary" startContent={<CrossIcon width="15" height="15"/>} onPress={() => openModal(i)}> Добавить модель</Button>
                                </div>
                                <div className="flex flex-col gap-5 pt-5">
                                    {x.items.map((b, j) => {
                                        return (
                                            <div key={j} className="flex justify-between">
                                                <div className="flex gap-2">
                                                    <Avatar radius="sm" className="w-[50px] h-[50px]" src={`https:${volHost(b.model?.artcodeWB)}/images/big/1.jpg`} />
                                                    <div className="flex flex-col">
                                                        <span className="font-bold">{b.model?.name} · {b.model?.color}</span>
                                                        <div className="flex gap-2">
                                                            <span className="text-warning">КИЗ · {b.model?.barcode}</span>
                                                            <span className="text-secondary">АРТ · {b.model?.artcode}</span>
                                                            <span className="text-cyan-600">РАЗМЕР · {b.model?.size}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex gap-3">
                                                    <div className="flex items-center justify-center pt-2 pb-2 pl-3 pr-3 bg-gray-300 rounded-xl">
                                                        <span className="font-bold">{b.amount} шт.</span>
                                                    </div>
                                                    <Button onPress={() => deleteModel(b.model.barcode, i)} className="min-h-full" color="danger" startContent={<DeleteIcon width="20px" height="20px"/>}></Button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </AccordionItem>
                        )
                    })}
            </Accordion>
            </div>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="4xl">
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Добавить товары в Короб-{currentBox + 1}</ModalHeader>
                            <ModalBody >
                                <Input
                                    isClearable
                                    size="sm"
                                    className="w-full"
                                    placeholder="Поиск по моделям..."
                                    startContent={<SearchIcon/>}
                                    value={filterValue}
                                    onClear={onClear}
                                    onValueChange={onSearchChange}
                                />
                                <div className="flex flex-col gap-5 overflow-y-auto max-h-[300px]">
                                    {items.map((x, i) => {
                                        return (
                                            <div key={i} className="flex justify-between">
                                                <div className="flex gap-2">
                                                    <Avatar radius="sm" className="w-[50px] h-[50px]" src={`https:${volHost(x?.artcodeWB)}/images/big/1.webp`} />
                                                    <div className="flex flex-col">
                                                        <span className="font-bold">{x.name} · {x.color}</span>
                                                        <div className="flex gap-2">
                                                            <span className="text-warning">КИЗ · {x.barcode}</span>
                                                            <span className="text-secondary">АРТ · {x.artcode}</span>
                                                            <span className="text-cyan-600">РАЗМЕР · {x.size}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Input  value={setupModels.filter(z => z.model.barcode === x.barcode)[0]?.amount || ""}
                                                           key={i} onChange={(e) => setupModelsInput(x, e.target.value)} className="max-w-[70px]" placeholder="0 шт."/>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="w-full flex justify-center">
                                    <Pagination
                                        isCompact
                                        showControls
                                        showShadow
                                        color="primary"
                                        page={page}
                                        total={Math.ceil(filteredItems.length / rowsPerPage)}
                                        onChange={setPage}
                                    />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Закрыть
                                </Button>
                                <Button color="primary" onPress={addModelInBox}>
                                    Сохранить
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}