import {useEffect, useState} from "react";
import axios from "axios";
import {api} from "../../../utils/axios.config";
import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem} from "@nextui-org/react";
import {LoaderOverlay} from "../../atoms/loaderOverlay/loaderOverlay";
import {DropZone} from "../../organisms/dropzone/dropzone";

export const UploadShkModal = ({isOpen, onOpen, onOpenChange, supplie}) => {

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    const handleUpload = () => {
        setLoading(true);
        const shk = [...data];
        const result = shk.map(x => x[2]).slice(1, shk.length);
        axios.post(`${api}/bid/shk?supplie=${supplie}`, result).finally(() => {
            setLoading(false);
            onOpenChange();
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            placement="top-center"
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        {loading ? <LoaderOverlay/> : ""}
                        <ModalHeader className="flex flex-col gap-1">Загрузка ШК</ModalHeader>
                        <ModalBody>
                            <DropZone data={data} setData={setData} />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="flat" onPress={onClose}>
                                Отмена
                            </Button>
                            <Button color="success" onPress={handleUpload}>
                                <span className="text-white">Сохранить</span>
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    )
}