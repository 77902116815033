import {Button} from "@nextui-org/react";
import {Route, Routes} from "react-router-dom";
import {HomePage} from "./components/pages/home/home";
import {HomeLayout} from "./layout/homeLayout";
import {SuppliesPage} from "./components/pages/supplies/supplies";
import {SupplieEditPage} from "./components/pages/supplieEdit/supplieEdit";
import {Test} from "./components/pages/test";
import {ModelsPage} from "./components/pages/models/models";
import {ProductionSuppliesPage} from "./components/pages/productionSupplies/productionSupplies";
import {ScanningPage} from "./components/pages/scanning/scanning";
import {ProductionSupplieEdit} from "./components/pages/productionSupplieEdit/productionSupplieEdit";
import {AnalyticPage} from "./components/pages/analytic/analytic";
import {StagePage} from "./components/pages/stage/stage";
import {TimeLineStagesPage} from "./components/pages/timeLineStages/timeLineStages";
import {PlanningPage} from "./components/pages/planning/planning";
import {AuthPage} from "./components/pages/auth/auth";
import {AdCompaniesPage} from "./components/pages/adCompanies/adComapnies";
import {SettingsPage} from "./components/pages/adCompanies/settings/settings.page";

function App() {
  return (
    <Routes>
        {localStorage.getItem("token") == "true" ?
        <Route path="/" element={<HomeLayout/>}>
            <Route index element={<SuppliesPage/>}/>
            <Route path="supplies/edit/:id" element={<SupplieEditPage/>}/>
            <Route path="/production/supplies/edit/:id" element={<ProductionSupplieEdit/>}/>
            <Route path="/production/supplies/stages/:id" element={<TimeLineStagesPage/>}/>
            <Route path="models" element={<ModelsPage/>}/>
            <Route path="production/supplies" element={<ProductionSuppliesPage/>}/>
            <Route path="scanning" element={<ScanningPage/>}/>
            <Route path="analytic" element={<AnalyticPage/>}/>
            <Route path="stage/:name" element={<StagePage/>}/>
            <Route path="planning" element={<PlanningPage/>}/>
            <Route path="advert" element={<AdCompaniesPage/>}/>
            <Route path="advert/:advertId" element={<SettingsPage/>}/>
        </Route>
        :
        <Route path="/" element={<AuthPage/>}/>
        }
    </Routes>
  );
}

export default App;
