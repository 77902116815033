import {useCallback, useEffect, useMemo, useState} from "react";
import axios from "axios";
import {api} from "../../../utils/axios.config";
import {formatDate} from "../../../utils/formatDate";
import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Button,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    DropdownSection,
    DropdownItem,
    cn,
    Chip,
    User,
    Pagination, useDisclosure,
} from "@nextui-org/react";
import {ActionsIcon} from "../../../icons/actions";
import {DownloadFileIcon} from "../../../icons/download_file";
import {RemoveFileIcon} from "../../../icons/remove_file";
import {useNavigate} from "react-router-dom";
import {iconClasses} from "../supplies/supplies";
import {SearchIcon} from "../../../icons/search";
import {ChevronDownIcon} from "../../../icons/chevron_down";
import * as PropTypes from "prop-types";
import {CrossIcon} from "../../../icons/cross";
import {CreateSupplieModal} from "../../molecules/createSupplieModal/createSupplieModal";
import {EditIcon} from "../../../icons/edit";
import {StatIcon} from "../../../icons/stat";
import {StagesIcon} from "../../../icons/stages";

const INITIAL_VISIBLE_COLUMNS = ["name", "date", "total", "stock", "shop", "actions"]

const columns = [
    {name: "НАЗВАНИЕ", uid: "name"},
    {name: "ДАТА", uid: "date"},
    {name: "МОДЕЛЕЙ", uid: "total"},
    {name: "СКЛАД", uid: "stock"},
    {name: "МАГАЗИН", uid: "shop"},
    {name: "ДЕЙСТВИЯ", uid: "actions"},
];

export const ProductionSuppliesPage = () => {
    const navigator = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectedShops, setSelectedShops] = useState("all");
    const [shops, setShops] = useState([]);
    const [filterValue, setFilterValue] = useState("");
    const [selectedKeys, setSelectedKeys] = useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [statusFilter, setStatusFilter] = useState("all");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [stock, setStock] = useState([]);
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [sortDescriptor, setSortDescriptor] = useState({
        column: "name",
        direction: "ascending",
    });
    const [page, setPage] = useState(1);

    const hasSearchFilter = Boolean(filterValue);

    useEffect(() => {
        axios.get(`${api}/shop/list`).then(response => {
            setShops(response.data)
        });
        axios.get(`${api}/stock/list`).then(response => {
            setStock(response.data);
        })
        axios.get(`${api}/production/bid/list`).then(response => {
            setData(response.data)
        })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const headerColumns = useMemo(() => {
        if (visibleColumns === "all") return columns;

        return columns.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);

    const filteredItems = useMemo(() => {
        let filteredUsers = [...data];

        if (selectedShops !== "all" && Array.from(selectedShops).length !== shops.length) {
            filteredUsers = filteredUsers.filter((user) =>
                Array.from(selectedShops).includes(user.shop.id),
            );
        }

        if (hasSearchFilter) {
            filteredUsers = filteredUsers.filter((user) =>
                user.name.toLowerCase().includes(filterValue.toLowerCase()) || user.id.toLowerCase().includes(filterValue.toLowerCase()),
            );
        }

        return filteredUsers;
    }, [data, filterValue, statusFilter, selectedShops]);

    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const items = useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);

    const sortedItems = useMemo(() => {
        return [...items].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);

     const deleteSupplie = (id) => {
         axios.delete(`${api}/production/bid?id=${id}`).then(() => {
             window.location.reload();
         })
     }

    const productionDownload = async (id) => {
        const response = await axios.get(`${api}/report/production?id=${id}`, {
            responseType: 'blob',
        });
        const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `production-${id}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }



    const renderCell = useCallback((x, columnKey) => {
        switch (columnKey) {
            case "shop":
                return (
                    <span>{x.shop.name}</span>
                )
            case "stock":
                return (
                    <span>{x.stock.name}</span>
                )
            case "date":
                return (
                    <span>{formatDate(x.date)}</span>
                )
            case "name":
                return (
                    <span>{x.name}</span>
                )
            case "total":
                return (
                    <span>{x.items?.length}</span>
                )
            case "actions":
                return (
                    <div className="flex gap-2">
                        <Dropdown>
                            <DropdownTrigger>
                                <Button color="secondary">
                                    <ActionsIcon className="cursor-pointer" width="20" height="20"/>
                                    <span>Управление</span>
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu variant="faded" aria-label="Dropdown menu with description">
                                <DropdownSection title="Действия" showDivider>
                                    <DropdownItem
                                        key="new"
                                        onClick={() => navigator("/production/supplies/edit/" + x.id)}
                                        description="Редактировать заявку"
                                        startContent={<EditIcon width="20" height="20" className={iconClasses} />}
                                    >
                                        Редактирование
                                    </DropdownItem>
                                    <DropdownItem
                                        key="new2"
                                        onClick={() => productionDownload(x.id)}
                                        description="Этикетки для отслеживания"
                                        startContent={<DownloadFileIcon width="20" height="20" className={iconClasses} />}
                                    >
                                        Этикетки
                                    </DropdownItem>
                                </DropdownSection>
                                <DropdownSection title="Опасная зона">
                                    <DropdownItem
                                        key="delete"
                                        className="text-danger"
                                        color="danger"
                                        description=""
                                        onClick={() => deleteSupplie(x.id)}
                                        startContent={<RemoveFileIcon width="20" height="20" className={cn(iconClasses, "text-danger")} />}
                                    >
                                        Удалить заявку
                                    </DropdownItem>
                                </DropdownSection>
                            </DropdownMenu>
                        </Dropdown>
                        <Button color="primary" onClick={() => navigator("stages/" + x.id)}>
                            <StagesIcon className="cursor-pointer" width="20" height="20"/>
                            <span>Производство</span>
                        </Button>
                    </div>
                );
            default:
                return (
                    <></>
                )
        }
    }, []);

    const onNextPage = useCallback(() => {
        if (page < pages) {
            setPage(page + 1);
        }
    }, [page, pages]);

    const onPreviousPage = useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
        }
    }, [page]);

    const onRowsPerPageChange = useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);

    const onSearchChange = useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = useCallback(()=>{
        setFilterValue("")
        setPage(1)
    },[])

    const topContent = useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <div className="flex justify-between gap-3 items-end">
                    <Input
                        isClearable
                        size="sm"
                        className="w-full sm:max-w-[44%] pt-[-4px]"
                        placeholder="Поиск по заявкам..."
                        startContent={<SearchIcon/>}
                        value={filterValue}
                        onClear={() => onClear()}
                        onValueChange={onSearchChange}
                    />
                    <div className="flex gap-3">
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<ChevronDownIcon className="text-small" />} variant="flat">
                                    Столбцы
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={visibleColumns}
                                selectionMode="multiple"
                                onSelectionChange={setVisibleColumns}
                            >
                                {columns.map((column) => (
                                    <DropdownItem key={column.uid} className="capitalize">
                                        {column.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<ChevronDownIcon className="text-small" />} variant="flat">
                                    Магазины
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={selectedShops}
                                selectionMode="multiple"
                                onSelectionChange={setSelectedShops}
                            >
                                {shops.map((column) => (
                                    <DropdownItem key={column.id} className="capitalize">
                                        {column.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Button onPress={onOpen} size="md" color="success" startContent={<CrossIcon width="20" height="20"/>}>
                            <span className="text-white">Создать заявку</span>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }, [
        selectedShops,
        filterValue,
        statusFilter,
        visibleColumns,
        onRowsPerPageChange,
        data.length,
        onSearchChange,
        hasSearchFilter,
        shops
    ]);

    const bottomContent = useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
                <span className="w-[30%] text-small text-default-400"></span>
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="primary"
                    page={page}
                    total={pages}
                    onChange={setPage}
                />
                <div className="hidden sm:flex w-[30%] justify-end gap-2">
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onPreviousPage}>
                        Предыдущая
                    </Button>
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onNextPage}>
                        Следующая
                    </Button>
                </div>
            </div>
        );
    }, [selectedKeys, items.length, page, pages, hasSearchFilter]);


    if (loading) return "Loading..."

    return (
        <>
            <h3 className="text-gray-900 normal-case font-semibold tracking-tighter text-2xl m-3">Управление заявками производства</h3>
            <CreateSupplieModal isOpen={isOpen} stock={stock} shops={shops} onOpenChange={onOpenChange}/>
            <Table
                aria-label="Example table with custom cells, pagination and sorting"
                isHeaderSticky
                bottomContent={bottomContent}
                bottomContentPlacement="outside"
                selectedKeys={selectedKeys}
                topContent={topContent}
                topContentPlacement="outside"
                onSelectionChange={setSelectedKeys}
                onSortChange={setSortDescriptor}
            >
                <TableHeader columns={headerColumns}>
                    {(column) => (
                        <TableColumn
                            key={column.uid}
                            align={column.uid === "actions" ? "center" : "start"}
                            allowsSorting={column.sortable}
                        >
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody emptyContent={"Заявок нет"} items={items}>
                    {(item) => (
                        <TableRow key={item.id}>
                            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </>
    )
}