export const ScanerIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.77 10C2.34 10 2 9.66 2 9.23V6.92C2 4.21 4.21 2 6.92 2H9.23C9.66 2 10 2.34 10 2.77C10 3.2 9.66 3.54 9.23 3.54H6.92C5.05 3.54 3.54 5.06 3.54 6.92V9.23C3.54 9.66 3.19 10 2.77 10Z" fill="white"/>
            <path d="M21.23 10C20.81 10 20.46 9.66 20.46 9.23V6.92C20.46 5.05 18.94 3.54 17.08 3.54H14.77C14.34 3.54 14 3.19 14 2.77C14 2.35 14.34 2 14.77 2H17.08C19.79 2 22 4.21 22 6.92V9.23C22 9.66 21.66 10 21.23 10Z" fill="white"/>
            <path d="M17.0819 21.9997H15.6919C15.2719 21.9997 14.9219 21.6597 14.9219 21.2297C14.9219 20.8097 15.2619 20.4597 15.6919 20.4597H17.0819C18.9519 20.4597 20.4619 18.9397 20.4619 17.0797V15.6997C20.4619 15.2797 20.8019 14.9297 21.2319 14.9297C21.6519 14.9297 22.0019 15.2697 22.0019 15.6997V17.0797C22.0019 19.7897 19.7919 21.9997 17.0819 21.9997Z" fill="white"/>
            <path d="M9.23 22H6.92C4.21 22 2 19.79 2 17.08V14.77C2 14.34 2.34 14 2.77 14C3.2 14 3.54 14.34 3.54 14.77V17.08C3.54 18.95 5.06 20.46 6.92 20.46H9.23C9.65 20.46 10 20.8 10 21.23C10 21.66 9.66 22 9.23 22Z" fill="white"/>
            <path d="M18.4595 11.2305H17.0995H6.89953H5.53953C5.10953 11.2305 4.76953 11.5805 4.76953 12.0005C4.76953 12.4205 5.10953 12.7705 5.53953 12.7705H6.89953H17.0995H18.4595C18.8895 12.7705 19.2295 12.4205 19.2295 12.0005C19.2295 11.5805 18.8895 11.2305 18.4595 11.2305Z" fill="white"/>
            <path d="M6.89844 13.9405V14.2705C6.89844 15.9305 8.23844 17.2705 9.89844 17.2705H14.0984C15.7584 17.2705 17.0984 15.9305 17.0984 14.2705V13.9405C17.0984 13.8205 17.0084 13.7305 16.8884 13.7305H7.10844C6.98844 13.7305 6.89844 13.8205 6.89844 13.9405Z" fill="white"/>
            <path d="M6.89844 10.0605V9.73047C6.89844 8.07047 8.23844 6.73047 9.89844 6.73047H14.0984C15.7584 6.73047 17.0984 8.07047 17.0984 9.73047V10.0605C17.0984 10.1805 17.0084 10.2705 16.8884 10.2705H7.10844C6.98844 10.2705 6.89844 10.1805 6.89844 10.0605Z" fill="white"/>
        </svg>
    )
}