export const SkusIcon = () => {
    return (
        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3313_11)">
                <path d="M0 0H1.62987V13.9792H0V0Z" fill="white"/>
                <path d="M2.85156 0H4.48143V13.9792H2.85156V0Z" fill="white"/>
                <path d="M5.09375 0H6.72362V13.9792H5.09375V0Z" fill="white"/>
                <path d="M7.94531 0H8.76015V13.9792H7.94531V0Z" fill="white"/>
                <path d="M9.98242 0H10.7973V13.9792H9.98242V0Z" fill="white"/>
                <path d="M12.0195 0H14.2606V13.9792H12.0195V0Z" fill="white"/>
                <path d="M16.0938 0H18.131V13.9792H16.0938V0Z" fill="white"/>
                <path d="M19.1504 0H19.9652V13.9792H19.1504V0Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_3313_11">
                    <rect width="20" height="14" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}