import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Button,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Chip,
    User,
    Pagination, useDisclosure, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Spinner,
} from "@nextui-org/react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {SearchIcon} from "../../../icons/search";
import {ChevronDownIcon, PlusIcon} from "@heroicons/react/16/solid";
import axios from "axios";
import {api} from "../../../utils/axios.config";
import {useNavigate} from "react-router-dom";

const statusColorMap = {
    9: "success",
    8: "secondary",
    4: "primary",
    7: "danger",
    11: "warning",
};

const statusBidderColorMap = {
    true: "success",
    false: "danger",
    null: "danger"
};


const statusOptions = [
    {name: "готова к запуску", uid: "4"},
    {name: "кампания завершена", uid: "7"},
    {name: "отказался", uid: "8"},
    {name: "идут показы", uid: "9"},
    {name: "кампания на паузе", uid: "11"},
];

const columns = [
    {name: "Кабинет", uid: "cabinet"},
    {name: "компания", uid: "advert"},
    {name: "название", uid: "name"},
    {name: "биддер", uid: "statusBidder"},
    {name: "статус", uid: "statusWb"},
    {name: "баланс", uid: "balance"},
    {name: "ctr", uid: "ctr"},
    {name: "текущая позиция", uid: "currentPosition"},
    {name: "заданная позиция", uid: "setPosition"},
    {name: "cpm веб", uid: "cpmWeb"},
    {name: "cpm в рк", uid: "cpmAdvert"},
    {name: "cpm старт", uid: "cpmStart"},
    {name: "cpm сет", uid: "setCpm"},
 /*   {name: "ключи", uid: "keys"},
    {name: "настройка", uid: "settings"},*/
];
const INITIAL_VISIBLE_COLUMNS = ["cabinet", "advert", "statusBidder", "statusWb", "name", "balance", "ctr", "currentPosition", "setPosition", "cpmWeb", "cpmAdvert", "cpmStart", "setCpm", "settings"];

const getStatusText = (status) => {
    switch (status) {
        case 11:
            return "пауза";
        case 4:
            return "готова к запуску";
        case 7:
            return "завершена";
        case 8:
            return "отказ";
        case 9:
            return "в работе";
        default:
            return "неопределенный"; // Изменено с "пенис" на более нейтральный вариант
    }
};

const getStatusBidder = (status) => {
    switch (status) {
        case true:
            return "в работе";
        case false:
            return "пауза";
        case null:
            return "пауза";
        default:
            return "неопределенный";
    }
};

export const AdCompaniesPage = () => {
    const {
        isOpen: isSettingsonModalOpen,
        onOpen: onOpenSettingsonModal,
        onOpenChange: onCloseSettingsonModal,
    } = useDisclosure();
    const {
        isOpen: isKeywordsModalOpen,
        onOpen: onOpenKeywordsonModal,
        onOpenChange: onCloseKeywordsonModal,
    } = useDisclosure();

    const {
        isOpen: a,
        onOpen: b,
        onOpenChange: c,
    } = useDisclosure();

    const [data, setData] = useState([]);
    const [filterValue, setFilterValue] = useState("");
    const [selectedKeys, setSelectedKeys] = useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [statusFilter, setStatusFilter] = useState("all");
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentAdvert, setCurrentAdvert] = useState(data.length > 0 ? data[0] : {});
    const [loading, setLoading] = useState(true);
    const navigator = useNavigate();
    const [shops, setShops] = useState([]);
    const [selectedShops, setSelectedShops] = useState("all");

    const [sortDescriptor, setSortDescriptor] = useState({
        column: "age",
        direction: "ascending",
    });
    const [page, setPage] = useState(1);

    useEffect(() => {
        axios.get(`${api}/shop/list`).then(response => {
            setShops(response.data)
        });
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${api}/advert`);
                setData(response.data);
            } catch (error) {
                // Handle errors if needed
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        // Call fetchData initially
        fetchData();

        // Set up an interval to call fetchData every minute
        const intervalId = setInterval(fetchData, 60000); // 60000 milliseconds = 1 minute

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array means this effect runs only once on mount

    const hasSearchFilter = Boolean(filterValue);

    const headerColumns = useMemo(() => {
        if (visibleColumns === "all") return columns;

        return columns.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);

    const filteredItems = useMemo(() => {
        let filteredUsers = [...data];

        if (hasSearchFilter) {
            filteredUsers = filteredUsers.filter((user) =>
                user.name.toLowerCase().includes(filterValue.toLowerCase()),
            );
        }

        if (selectedShops !== "all" && Array.from(selectedShops).length !== shops.length) {
            filteredUsers = filteredUsers.filter((user) =>
                Array.from(selectedShops).includes(user.shop.id),
            );
        }

        if (statusFilter !== "all" && Array.from(statusFilter).length !== statusOptions.length) {
            filteredUsers = filteredUsers.filter((user) =>
                Array.from(statusFilter).includes(user.status),
            );
        }

        const statusPriority = [4, 9, 11, 8, 7];

        filteredUsers.sort((a, b) => {
            // Get the priority of each user based on their status
            let priorityA = statusPriority.indexOf(a.status);
            let priorityB = statusPriority.indexOf(b.status);

            // If both users have the same status priority, consider settings.status
            if (priorityA === priorityB) {
                // If 'a.settings.status' is true, it should be sorted before 'b'
                if (a.settings.status && !b.settings.status) {
                    return -1;
                }
                // If 'b.settings.status' is true, it should be sorted before 'a'
                else if (b.settings.status && !a.settings.status) {
                    return 1;
                }
            }

            // Otherwise, prioritize by status priority
            return priorityA - priorityB;
        });

        return filteredUsers;
    }, [data, filterValue, statusFilter, selectedShops]);

    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const items = useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);

    const sortedItems = useMemo(() => {
        return [...items].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);
    
    const onNextPage = useCallback(() => {
        if (page < pages) {
            setPage(page + 1);
        }
    }, [page, pages]);

    const onPreviousPage = useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
        }
    }, [page]);

    const onRowsPerPageChange = useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);

    const onSearchChange = useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = useCallback(()=>{
        setFilterValue("")
        setPage(1)
    },[])

    const topContent = useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <div className="flex justify-between gap-3 items-end">
                    <Input
                        isClearable
                        className="w-full sm:max-w-[44%]"
                        placeholder="Поиск..."
                        startContent={<SearchIcon />}
                        value={filterValue}
                        onClear={() => onClear()}
                        onValueChange={onSearchChange}
                    />
                    <div className="flex gap-3">
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<ChevronDownIcon className="text-small" />} variant="flat">
                                    статус
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={statusFilter}
                                selectionMode="multiple"
                                onSelectionChange={setStatusFilter}
                            >
                                {statusOptions.map((status) => (
                                    <DropdownItem key={status.uid} className="capitalize">
                                        {status.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<ChevronDownIcon className="text-small" />} variant="flat">
                                    колонки
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={visibleColumns}
                                selectionMode="multiple"
                                onSelectionChange={setVisibleColumns}
                            >
                                {columns.map((column) => (
                                    <DropdownItem key={column.uid} className="capitalize">
                                        {column.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<ChevronDownIcon className="text-small" />} variant="flat">
                                    Магазины
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={selectedShops}
                                selectionMode="multiple"
                                onSelectionChange={setSelectedShops}
                            >
                                {shops.map((column) => (
                                    <DropdownItem key={column.id} className="capitalize">
                                        {column.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-default-400 text-small">Всего {data.length} компаний</span>
                    <label className="flex items-center text-default-400 text-small">
                        Компаний на страницу:
                        <select
                            className="bg-transparent outline-none text-default-400 text-small"
                            onChange={onRowsPerPageChange}
                        >
                            <option value="15">15</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                        </select>
                    </label>
                </div>
            </div>
        );
    }, [
        filterValue,
        statusFilter,
        visibleColumns,
        onRowsPerPageChange,
        data.length,
        onSearchChange,
        hasSearchFilter,
        selectedShops
    ]);

    const bottomContent = useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
        <span className="w-[30%] text-small text-default-400">
          {selectedKeys === "all"
              ? "All items selected"
              : `${selectedKeys.size} of ${filteredItems.length} selected`}
        </span>
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="primary"
                    page={page}
                    total={pages}
                    onChange={setPage}
                    initialPage={1}
                    defaultValue={1}
                />
                <div className="hidden sm:flex w-[30%] justify-end gap-2">
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onPreviousPage}>
                        назад
                    </Button>
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onNextPage}>
                        вперед
                    </Button>
                </div>
            </div>
        );
    }, [selectedKeys, items.length, page, pages, hasSearchFilter]);

    const openSettings = (x) => {
        setCurrentAdvert(x);
        onOpenSettingsonModal();
    }

    const renderCell = useCallback((x, columnKey) => {
        const cellValue = x[columnKey];

        switch (columnKey) {
            case "cabinet":
                return (
                   <>
                       {x.shop.name}
                   </>
                )
            case "advert":
                return (
                    <>
                        {x.advertId}
                    </>
                );
            case "name":
                return (
                    <>
                        {x.name}
                    </>
                );
            case "statusBidder":
                return (
                    <>
                        <Chip className="capitalize" color={statusBidderColorMap[x.settings?.status]} size="sm" variant="flat">
                            {getStatusBidder(x.settings?.status)}
                        </Chip>
                    </>
                );
            case "statusWb":
                return (
                    <>
                        <Chip className="capitalize" color={statusColorMap[x.status]} size="sm" variant="flat">
                            {getStatusText(x.status)}
                        </Chip>
                    </>
                );
            case "balance":
                return (
                    <>
                        {x.balance}
                    </>
                );
            case "ctr":
                return (
                    <>
                        {x.statistics.ctr}
                    </>
                );
            case "currentPosition":
                return (
                    <>
                        {x.settings?.currentPosition}
                    </>
                );
            case "setPosition":
                return (
                    <>
                        {x.settings?.position}
                    </>
                );
            case "cpmWeb":
                return (
                    <>
                        {x.settings?.currentCpm}
                    </>
                );
            case "cpmAdvert":
                return (
                    <>
                        {x.settings?.cpm}
                    </>
                );
            case "cpmStart":
                return (
                    <>
                        {x.settings?.startCpm}
                    </>
                );
            case "setCpm":
                return (
                    <>
                        {x.settings?.setCpm}
                    </>
                );
            case "keys":
                return (
                    <>
                        <svg onClick={() => {
                            setCurrentAdvert(x);
                            onOpenKeywordsonModal();
                        }} className="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.7887 4.22031C16.8287 1.27031 12.0287 1.27031 9.08868 4.22031C7.01868 6.27031 6.39868 9.22031 7.19868 11.8203L2.49868 16.5203C2.16868 16.8603 1.93868 17.5303 2.00868 18.0103L2.30868 20.1903C2.41868 20.9103 3.08868 21.5903 3.80868 21.6903L5.98868 21.9903C6.46868 22.0603 7.13868 21.8403 7.47868 21.4903L8.29868 20.6703C8.49868 20.4803 8.49868 20.1603 8.29868 19.9603L6.35868 18.0203C6.06868 17.7303 6.06868 17.2503 6.35868 16.9603C6.64868 16.6703 7.12868 16.6703 7.41868 16.9603L9.36868 18.9103C9.55868 19.1003 9.87868 19.1003 10.0687 18.9103L12.1887 16.8003C14.7787 17.6103 17.7287 16.9803 19.7887 14.9303C22.7387 11.9803 22.7387 7.17031 19.7887 4.22031ZM14.4987 12.0003C13.1187 12.0003 11.9987 10.8803 11.9987 9.50031C11.9987 8.12031 13.1187 7.00031 14.4987 7.00031C15.8787 7.00031 16.9987 8.12031 16.9987 9.50031C16.9987 10.8803 15.8787 12.0003 14.4987 12.0003Z" fill="white"/>
                        </svg>

                    </>
                );
            case "settings":
                return (
                    <>
                        <svg onClick={() => onOpenSettingsonModal()} className="cursor-pointer hover:fill-primary-400" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.1 9.2214C18.29 9.2214 17.55 7.9414 18.45 6.3714C18.97 5.4614 18.66 4.3014 17.75 3.7814L16.02 2.7914C15.23 2.3214 14.21 2.6014 13.74 3.3914L13.63 3.5814C12.73 5.1514 11.25 5.1514 10.34 3.5814L10.23 3.3914C9.78 2.6014 8.76 2.3214 7.97 2.7914L6.24 3.7814C5.33 4.3014 5.02 5.4714 5.54 6.3814C6.45 7.9414 5.71 9.2214 3.9 9.2214C2.86 9.2214 2 10.0714 2 11.1214V12.8814C2 13.9214 2.85 14.7814 3.9 14.7814C5.71 14.7814 6.45 16.0614 5.54 17.6314C5.02 18.5414 5.33 19.7014 6.24 20.2214L7.97 21.2114C8.76 21.6814 9.78 21.4014 10.25 20.6114L10.36 20.4214C11.26 18.8514 12.74 18.8514 13.65 20.4214L13.76 20.6114C14.23 21.4014 15.25 21.6814 16.04 21.2114L17.77 20.2214C18.68 19.7014 18.99 18.5314 18.47 17.6314C17.56 16.0614 18.3 14.7814 20.11 14.7814C21.15 14.7814 22.01 13.9314 22.01 12.8814V11.1214C22 10.0814 21.15 9.2214 20.1 9.2214ZM12 15.2514C10.21 15.2514 8.75 13.7914 8.75 12.0014C8.75 10.2114 10.21 8.7514 12 8.7514C13.79 8.7514 15.25 10.2114 15.25 12.0014C15.25 13.7914 13.79 15.2514 12 15.2514Z" fill="white"/>
                        </svg>
                    </>
                );
            default:
                return cellValue;
        }
    }, [a]);

    return (
        <>
            <h3 className="text-gray-900 normal-case font-semibold tracking-tighter text-2xl m-3">Рекламные компании</h3>
            <Table
                color="primary"
                selectionMode="single"
                aria-label="Example table with custom cells, pagination and sorting"
                bottomContent={bottomContent}
                bottomContentPlacement="outside"
                selectedKeys={selectedKeys}
                sortDescriptor={sortDescriptor}
                topContent={topContent}
                topContentPlacement="outside"
                onSelectionChange={setSelectedKeys}
                onSortChange={setSortDescriptor}
                classNames={{
                    table: "min-h-[400px]",
                }}
            >
                <TableHeader columns={headerColumns}>
                    {(column) => (
                        <TableColumn
                            key={column.uid}
                            align={column.uid === "actions" ? "center" : "start"}
                            allowsSorting={column.sortable}
                        >
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody isLoading={loading} loadingContent={<Spinner label="Загрузка..." />}
                            items={sortedItems}>
                    {(item) => (
                        <TableRow className="cursor-pointer" key={item.id} onClick={() => navigator(`/advert/${item.advertId}`)}>
                            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </>
    )
}