import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Checkbox, Input, Link} from "@nextui-org/react";
import {MailIcon} from "../../../icons/main.icon";
import {LockIcon} from "../../../icons/lock.ixon";
import {useEffect, useState} from "react";

export const AuthPage = () => {
    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [isInvalid, setIsInvalid] = useState(false);

    useEffect(() => {
        onOpen()
    }, []);


    const auth = () => {
        if (login === "manager" && password === "777") {
            localStorage.setItem("token", "true");
            window.location.reload();
        }
        else {
            setIsInvalid(true);
        }
    }

    return (
        <div className="w-full h-[100vh] flex justify-center items-center">
            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                placement="top-center"
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Вход</ModalHeader>
                            <ModalBody>
                                <Input
                                    autoFocus
                                    value={login}
                                    isInvalid={isInvalid}
                                    onValueChange={setLogin}
                                    endContent={
                                        <MailIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                                    }
                                    label="Логин"
                                    placeholder="введите ваш логин"
                                    variant="bordered"
                                />
                                <Input
                                    isInvalid={isInvalid}
                                    value={password}
                                    onValueChange={setPassword}
                                    endContent={
                                        <LockIcon className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                                    }
                                    label="Пароль"
                                    placeholder="введите ваш пароль"
                                    type="password"
                                    variant="bordered"
                                />
                                <div className="flex py-2 px-1 justify-between">
                                    <Checkbox
                                        classNames={{
                                            label: "text-small",
                                        }}
                                    >
                                        Запомнить меня
                                    </Checkbox>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onPress={auth}>
                                    вход
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    )
}