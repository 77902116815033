import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
    Input,
    Switch,
    Divider,
    Spinner,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    getKeyValue,
    Pagination
} from "@nextui-org/react";
import {useEffect, useMemo, useState} from "react";
import axios from "axios";
import {api} from "../../../../utils/axios.config";
import {useParams} from "react-router-dom";
import {LoaderOverlay} from "../../../atoms/loaderOverlay/loaderOverlay";

export const SettingsPage = () => {

    const [advert, setAdvert] = useState();
    const [settings, setSettings] = useState();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const params = useParams();
    const rowsPerPage = 10;
    const pages = Math.ceil(advert?.keywords.length / rowsPerPage);
    const [keywords, setKeywords] = useState([]);

    useEffect(() => {
        axios.get(`${api}/advert/current?advertId=${params.advertId}`).then(response => {
            setAdvert(response.data);
            setSettings(response.data.settings);
            setKeywords(response.data.keywords);
        }).finally(() => setLoading(false));
    }, [])

    console.log(pages)
    console.log(page)
    const items = useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return keywords.slice(start, end);
    }, [page, advert?.keywords]);


    const updateSettings = () => {
        setLoading(true)
        advert.keywords = []
        advert.settings = settings;
        axios.post(`${api}/advert/settings`, advert).then(response => {

        }).finally(() => setLoading(false))
    }

    return (
       <>
           {loading && <LoaderOverlay/>}
           <h3 className="text-gray-900 normal-case font-semibold tracking-tighter text-2xl m-3">Управление компанией {advert?.name}</h3>
           <Divider/>
           <div className="flex gap-2 items-center">
               <h4 className="text-gray-500 normal-case font-semibold tracking-tighter text-xl m-3">Настройки биддера</h4>
               <div className="w-[290px]">
                   <Switch isSelected={settings?.status} value={settings?.status} onChange={(e) => setSettings(prevState => {
                       return {
                           ...prevState,
                           status: e.target.checked
                       };
                   })
                   } className="w-full" size="sm">
                       Управление компанией
                   </Switch>
               </div>
           </div>


           <div className="pt-5">
               <Input
                   size="lg"
                   isClearable
                   type="text"
                   label="Ключевой запрос"
                   labelPlacement="outside"
                   variant="flat"
                   onChange={(e) => setSettings(prevState => {
                       return {
                           ...prevState,
                           keyword: e.target.value
                       };
                   })
                   }
                   value={settings?.keyword}
                   onClear={() => console.log("input cleared")}
                   className="w-full"
                   placeholder="Ключевой запрос..."
               />
               <div className="flex gap-5 pt-5">
                   <Input
                       size="lg"
                       isClearable
                       type="text"
                       label="Макс cpm"
                       labelPlacement="outside"
                       variant="flat"
                       value={settings?.maxCpm}
                       onChange={(e) => setSettings(prevState => {
                           return {
                               ...prevState,
                               maxCpm: e.target.value
                           };
                       })
                       }
                       onClear={() => console.log("input cleared")}
                       className="w-full"
                       placeholder="000"
                   />
                   <Input
                       size="lg"
                       isClearable
                       type="text"
                       label="Начальный cpm"
                       labelPlacement="outside"
                       variant="flat"
                       value={settings?.startCpm}
                       onChange={(e) => setSettings(prevState => {
                           return {
                               ...prevState,
                               startCpm: e.target.value
                           };
                       })
                       }
                       onClear={() => console.log("input cleared")}
                       className="w-full"
                       placeholder="000"
                   />
               </div>
               <div className="flex gap-5 items-center pt-5">
                   <div>
                       <Input
                           isClearable
                           type="text"
                           label="Желаемая позиция"
                           labelPlacement="outside"
                           variant="flat"
                           value={settings?.position}
                           onChange={(e) => setSettings(prevState => {
                               return {
                                   ...prevState,
                                   position: e.target.value
                               };
                           })
                           }
                           onClear={() => console.log("input cleared")}
                           className="w-full"
                           placeholder="0"
                       />
                   </div>
               </div>
           </div>
           <Divider className="my-5"/>
           <div className="flex gap-2 items-center">
               <h4 className="text-gray-500 normal-case font-semibold tracking-tighter text-xl m-3">Настройки ключевых запросов</h4>
               <Switch value={settings?.status} onChange={(e) => setSettings(prevState => {
                   return {
                       ...prevState,
                       status: e.target.checked
                   };
               })
               } className="w-full" size="sm">
                   Управление ключами
               </Switch>
           </div>

           <div className="flex">
               <div className="flex flex-col gap-2">
               <Table
                   isHeaderSticky
                   selectionMode="multiple"
                   aria-label="Example table with client side sorting"
                   classNames={{
                       base: "max-h-[520px]",
                       table: "min-h-[420px]",
                   }}
               >
                   <TableHeader>
                       <TableColumn key="name">Кластер</TableColumn>
                       <TableColumn key="count">Количество показов</TableColumn>
                   </TableHeader>
                   <TableBody>
                       {items.map((x, i) => {
                           return (
                               <TableRow key={i}>
                                   <TableCell>{x.cluster}</TableCell>
                                   <TableCell>{x.count}</TableCell>
                               </TableRow>
                           )
                       })}
                   </TableBody>
               </Table>
               <Pagination
                   isCompact
                   showControls
                   showShadow
                   color="secondary"
                   page={page}
                   total={pages}
                   onChange={(page) => setPage(page)}
               />
               </div>
           </div>

           <Divider className="my-5"/>
           <div>
               <Button onClick={updateSettings} color="success">
                   Сохранить
               </Button>
           </div>
       </>
    )
}