import {Link, useLocation} from "react-router-dom";

export const SidebarButton = ({to, children, ...props}) => {

    const location = useLocation();

    const isActive = location.pathname === to;

    const baseStyles = 'w-full min-h-[44px] h-full flex items-center rounded-lg cursor-pointer transition duration-150 ease-in-out pl-3.5 pr-3.5 gap-3';
    const activeStyles = 'hover:bg-gray-300 bg-blue-600 text-white';
    const inactiveStyles = 'hover:bg-gray-300 text-gray-900';

    const buttonStyles = isActive ? `${baseStyles} ${activeStyles}` : `${baseStyles} ${inactiveStyles}`;

    return (
        <Link className="text-color-gray-900 max-w-full" {...props} to={to}>
            <div className={buttonStyles}>
                {children}
            </div>
        </Link>
    )
}