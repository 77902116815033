import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Button,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Chip,
    User,
    Pagination,
} from "@nextui-org/react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {SearchIcon} from "../../../icons/search";
import {ChevronDownIcon} from "../../../icons/chevron_down";
import axios from "axios";
import {api} from "../../../utils/axios.config";
import {volHost} from "../../../utils/volHost";

const statusColorMap = {
    active: "success",
    paused: "danger",
    vacation: "warning",
};

const INITIAL_VISIBLE_COLUMNS = ["name", "barcode", "artcode", "artcodeWB", "artcodeOZON", "size", "color", "material", "embossing", "sole", "print"];

const columns = [
    {name: "НАЗВАНИЕ", uid: "name"},
    {name: "БАРКОД", uid: "barcode"},
    {name: "АРТ", uid: "artcode", },
    {name: "АРТ-ВБ", uid: "artcodeWB"},
    {name: "АРТ-ОЗОН", uid: "artcodeOZON"},
    {name: "РАЗМЕР", uid: "size"},
    {name: "ЦВЕТ", uid: "color"},
    {name: "МАТЕРИАЛ", uid: "material"},
    {name: "ТИСНЕНИЕ", uid: "embossing"},
    {name: "ПОДОШВА", uid: "sole"},
    {name: "ПЕЧАТЬ", uid: "print"},
];

export const ModelsPage = () => {

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([])
    const [filterValue, setFilterValue] = useState("");
    const [selectedKeys, setSelectedKeys] = useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [statusFilter, setStatusFilter] = useState("all");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedShops, setSelectedShops] = useState("all");
    const [sortDescriptor, setSortDescriptor] = useState({
        column: "name",
        direction: "ascending",
    });
    const [page, setPage] = useState(1);
    const [shops, setShops] = useState([]);

    useEffect(() => {
        axios.get(`${api}/model/all`).then(response => {
            setUsers(response.data)
        })
        axios.get(`${api}/shop/list`).then(response => {
            setShops(response.data)
        }).finally(() => setLoading(false))
    }, [])

    const hasSearchFilter = Boolean(filterValue);

    const headerColumns = useMemo(() => {
        if (visibleColumns === "all") return columns;

        return columns.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);


    const filteredItems = useMemo(() => {
        let filteredUsers = [...users];

        if (selectedShops !== "all" && Array.from(selectedShops).length !== shops.length) {
            filteredUsers = filteredUsers.filter((user) =>
                Array.from(selectedShops).includes(user.idShop),
            );
        }

        if (hasSearchFilter) {
            filteredUsers = filteredUsers.filter((user) =>
                user.name?.toLowerCase().includes(filterValue.toLowerCase()) || user.id?.toLowerCase().includes(filterValue.toLowerCase())
                    || user.barcode?.toLowerCase().includes(filterValue.toLowerCase()) || user.artcode?.includes(filterValue),
            );
        }

        return filteredUsers;
    }, [users, filterValue, statusFilter, selectedShops]);

    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const items = useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);

    const sortedItems = useMemo(() => {
        return [...items].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);

    const renderCell = useCallback((user, columnKey) => {
        const cellValue = user[columnKey];

        switch (columnKey) {
            case "name":
                return (
                    <User
                        avatarProps={{radius: "lg", src: "https:" + volHost(user.artcodeWB) + "/images/big/1.webp"}}
                        description={user.id}
                        name={cellValue}
                    >
                        {user.name}
                    </User>
                );
            case "role":
                return (
                    <></>
                );
            case "status":
                return (
                   <></>
                );
            case "actions":
                return (
                  <></>
                );
            default:
                return cellValue;
        }
    }, []);

    const onNextPage = useCallback(() => {
        if (page < pages) {
            setPage(page + 1);
        }
    }, [page, pages]);

    const onPreviousPage = useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
        }
    }, [page]);

    const onRowsPerPageChange = useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);

    const onSearchChange = useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = useCallback(()=>{
        setFilterValue("")
        setPage(1)
    },[])

    const topContent = useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <div className="flex justify-between gap-3 items-end">
                    <Input
                        isClearable
                        size="sm"
                        className="w-full sm:max-w-[44%] pt-[-4px]"
                        placeholder="Поиск по моделям..."
                        startContent={<SearchIcon/>}
                        value={filterValue}
                        onClear={() => onClear()}
                        onValueChange={onSearchChange}
                    />
                    <div className="flex gap-3">
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<ChevronDownIcon className="text-small" />} variant="flat">
                                    Столбцы
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={visibleColumns}
                                selectionMode="multiple"
                                onSelectionChange={setVisibleColumns}
                            >
                                {columns.map((column) => (
                                    <DropdownItem key={column.uid} className="capitalize">
                                        {column.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<ChevronDownIcon className="text-small" />} variant="flat">
                                    Магазины
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={selectedShops}
                                selectionMode="multiple"
                                onSelectionChange={setSelectedShops}
                            >
                                {shops.map((column) => (
                                    <DropdownItem key={column.id} className="capitalize">
                                        {column.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        );
    }, [
        selectedShops,
        filterValue,
        statusFilter,
        visibleColumns,
        onRowsPerPageChange,
        users.length,
        onSearchChange,
        hasSearchFilter,
    ]);

    const bottomContent = useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
                <span className="w-[30%] text-small text-default-400"></span>
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="primary"
                    page={page}
                    total={pages}
                    onChange={setPage}
                />
                <div className="hidden sm:flex w-[30%] justify-end gap-2">
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onPreviousPage}>
                        Предыдущая
                    </Button>
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onNextPage}>
                        Следующая
                    </Button>
                </div>
            </div>
        );
    }, [selectedKeys, items.length, page, pages, hasSearchFilter]);

    if (loading) return "Loading..."

    return (
        <>
            <h3 className="text-gray-900 normal-case font-semibold tracking-tighter text-2xl m-3">Модели</h3>

            <Table
                aria-label="Example table with custom cells, pagination and sorting"
                isHeaderSticky
                bottomContent={bottomContent}
                bottomContentPlacement="outside"
                selectedKeys={selectedKeys}
                sortDescriptor={sortDescriptor}
                topContent={topContent}
                topContentPlacement="outside"
                onSelectionChange={setSelectedKeys}
                onSortChange={setSortDescriptor}
            >
                <TableHeader columns={headerColumns}>
                    {(column) => (
                        <TableColumn
                            key={column.uid}
                            align={column.uid === "actions" ? "center" : "start"}
                            allowsSorting={column.sortable}
                        >
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody emptyContent={"Моделей нет"} items={sortedItems}>
                    {(item) => (
                        <TableRow key={item.id}>
                            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </>
    );
}