import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Select,
    SelectItem
} from "@nextui-org/react";
import axios from "axios";
import {api} from "../../../utils/axios.config";
import {useState} from "react";

export const CreateSupplieModal = ({isOpen, onOpenChange, shops, stock}) => {

    const [supplie, setSupplie] = useState({});

    const createSupplie = () => {
        axios.post(`${api}/production/bid/save`, supplie).then(() => {
            window.location.reload();
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            placement="top-center"
        >
            <ModalContent className="max-w-[350px]">
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">Cоздание поставки</ModalHeader>
                        <ModalBody>
                            <Input
                                label="Название"
                                placeholder="Ввод названия заявки"
                                labelPlacement="outside"
                                className="max-w-xs"
                                onChange={(e) => setSupplie({...supplie, name: e.target.value})}
                            />
                            <Select
                                items={shops}
                                onChange={(e) => setSupplie({...supplie, shop: e.target.value})}
                                label="Магазин"
                                placeholder="Выбор магазина"
                                labelPlacement="outside"
                                className="max-w-xs"
                            >
                                {(x) => (
                                    <SelectItem key={x.id} textValue={x.name}>
                                        <span>{x.name}</span>
                                    </SelectItem>
                                )}
                            </Select>

                            <Select
                                items={stock}
                                onChange={(e) => setSupplie({...supplie, stock: e.target.value})}
                                label="Cклад"
                                placeholder="Выбор склада"
                                labelPlacement="outside"
                                className="max-w-xs"
                            >
                                {(x) => (
                                    <SelectItem key={x.id} textValue={x.name}>
                                        <span>{x.name}</span>
                                    </SelectItem>
                                )}
                            </Select>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onClose}>
                                Отмена
                            </Button>
                            <Button color="success" className="text-white" onPress={createSupplie}>
                                Создать
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    )
}