import {Area, AreaChart, Line, Tooltip} from "recharts";
import {useEffect, useState} from "react";
import axios from "axios";
import {api} from "../../../utils/axios.config";
import {Chip} from "@nextui-org/react";
import {StatIcon} from "../../../icons/stat";
import {useNavigate} from "react-router-dom";

export const AreaChartStage = ({stage, name, color}) => {

    const [data, setData] = useState([]);
    const [amount, setAmount] = useState(0);
    const [difference, setDifference] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${api}/analytic/week?stage=${stage}`).then(response => {
            setData(response.data.items);
            setDifference(response.data.difference);
            setAmount(response.data.amount)
        })
            .finally(() => setLoading(false))
    }, [])

    if(loading) return "Loading..."

    return (
        <div className="bg-gray-100 rounded-xl p-2">
            <div className="flex justify-between gap-5 items-center">
                <div className="flex flex-col gap-2">
                    <p className="pl-2">{name}</p>
                    <AreaChart width={150} height={50} data={data}>
                        <Tooltip />
                        <Line type="monotone" dataKey="value" stroke={color} activeDot={{ r: 2 }} />
                        <Area type="monotone" dataKey="value" stroke={color} fill={color} />
                    </AreaChart>
                </div>
                <div className="flex flex-col gap-1 relative">
                    <StatIcon onClick={() => navigate("/stage/" + stage)} className="cursor-pointer absolute right-[10px]"/>
                    <p className="text-white font-bold">{amount} шт.</p>
                    {difference >= 0 ?
                        <p className="text-success text-small">+{difference}%</p>
                        :
                        <p className="text-danger text-small">-{difference}%</p>
                    }
                    <span className="text-gray-500">от прошлой недели </span>
                </div>
            </div>
        </div>
    )
}