import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    Button,
    ButtonGroup,
    Chip,
    Code, getKeyValue, Pagination, Skeleton,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow, Tooltip
} from "@nextui-org/react";
import axios from "axios";
import {api} from "../../../utils/axios.config";


export const types = [
    {id: 1, value: "Крой", xvalue: "CUTTING"},
    {id: 2, value: "Заготовка", xvalue: "BLANK_PREPARATION"},
    {id: 3, value: "Сбивка", xvalue: "ASSEMBLY"},
    {id: 4, value: "Упаковка", xvalue: "PACKAGING"},
    {id: 5, value: "Склад", xvalue: "STOCK"},
    {id: 6, value: "Отправлено", xvalue: "DISPATCHED"},
    {id: 7, value: "Брак", xvalue: "DEFECT"}
]

const columns = [
    {name: "Статус", uid: "code"},
    {name: "НАЗВАНИЕ", uid: "name"},
    {name: "БАРКОД", uid: "barcode"},
    {name: "АРТ", uid: "artcode", },
    {name: "АРТ-ВБ", uid: "artcodeWB"},
    {name: "АРТ-ОЗОН", uid: "artcodeOZON"},
    {name: "РАЗМЕР", uid: "size"},
    {name: "ЦВЕТ", uid: "color"},
    {name: "МАТЕРИАЛ", uid: "material"},
    {name: "ТИСНЕНИЕ", uid: "embossing"},
    {name: "ПОДОШВА", uid: "sole"},
    {name: "ПЕЧАТЬ", uid: "print"},
];

export const ScanningPage = (callback, deps) => {

    const inputRef = useRef(null);
    const [focus, setFocus] = useState(false);
    const [data, setData] = useState("")
    const [type, setType] = useState(types[0]);
    const [rows, setRows] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);


    const requestModel = async (trackNumber) => {
        const body = {
            "trackNumber": trackNumber,
            "stage" : type.xvalue
        }
        axios.post(`${api}/tracking`, body)
             .then(response => {
                 setRows(prevState => {
                     const elements = [...prevState];
                     let newElement = response.data.item.model;
                     newElement.code = 200;
                     elements.push(newElement);
                     return elements;
                 })
             })
             .catch((error) => {
             console.log(error)
             setRows(prevState => {
                 const elements = [...prevState];
                 elements.push({
                     "code" : 400,
                     "status" : error.response.data,
                 })
                 return elements;
             })
         });
    }

    useEffect( () => {
        console.log(data)
        const elements = data.split("\n");
        if (elements.length > 0) {
            const element = elements[elements.length - 1]
            if (element.length === 15) {
                requestModel(element);
            }
        }
    }, [data]);

    const handleFocus = () => {
        inputRef.current.focus();
    };

    const pages = Math.ceil(rows.length / rowsPerPage);

    const items = useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return rows.reverse().slice(start, end);
    }, [page, rows, rowsPerPage]);


    const renderCell = useCallback((elements, columnKey) => {
        const cellValue = elements[columnKey];
        switch (columnKey) {
            default:
                return cellValue;
            }
        });

    const onNextPage = useCallback(() => {
        if (page < pages) {
            setPage(page + 1);
        }
    }, [page, pages]);

    const onPreviousPage = useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
        }
    }, [page]);

    const bottomContent = useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
                <span className="w-[30%] text-small text-default-400"></span>
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="primary"
                    page={page}
                    total={pages}
                    onChange={setPage}
                />
                <div className="hidden sm:flex w-[30%] justify-end gap-2">
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onPreviousPage}>
                        Предыдущая
                    </Button>
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onNextPage}>
                        Следующая
                    </Button>
                </div>
            </div>
        );
    }, [items.length, page, pages]);

    const renderCells = (row, index) => {
        if (row.code === 200) {
            return (
                <TableRow key={index}>
                <TableCell> <Chip color="success">
                    <span className="text-white">{row.code}</span>
                </Chip></TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.barcode}</TableCell>
                <TableCell>{row.artcode}</TableCell>
                <TableCell>{row.artcodeWB}</TableCell>
                <TableCell>{row.artcodeOZON}</TableCell>
                <TableCell>{row.size}</TableCell>
                <TableCell>{row.color}</TableCell>
                <TableCell>{row.material}</TableCell>
                <TableCell>{row.embossing}</TableCell>
                <TableCell>{row.sole}</TableCell>
                <TableCell>{row.print}</TableCell>
                </TableRow>
                )
        } else {
            return (
                <TableRow key={index}>
                    <TableCell> <Tooltip
                        content={
                            <div className="px-1 py-2">
                                <div className="text-small font-bold">Ошибка</div>
                                <div className="text-tiny">{row.status}</div>
                            </div>
                        }
                    >
                        <Chip color="danger">
                            {row.code}
                        </Chip>
                    </Tooltip></TableCell>
                <TableCell> <div className="h-3 w-full rounded-lg bg-gray-300"></div></TableCell>
            <TableCell> <div className="h-3 w-full rounded-lg bg-gray-300"></div></TableCell>
            <TableCell> <div className="h-3 w-full rounded-lg bg-gray-300"></div></TableCell>
            <TableCell> <div className="h-3 w-full rounded-lg bg-gray-300"></div></TableCell>
            <TableCell> <div className="h-3 w-full rounded-lg bg-gray-300"></div></TableCell>
            <TableCell> <div className="h-3 w-full rounded-lg bg-gray-300"></div></TableCell>
            <TableCell> <div className="h-3 w-full rounded-lg bg-gray-300"></div></TableCell>
            <TableCell> <div className="h-3 w-full rounded-lg bg-gray-300"></div></TableCell>
            <TableCell> <div className="h-3 w-full rounded-lg bg-gray-300"></div></TableCell>
            <TableCell> <div className="h-3 w-full rounded-lg bg-gray-300"></div></TableCell>
            <TableCell> <div className="h-3 w-full rounded-lg bg-gray-300"></div></TableCell>
                </TableRow>
            )
        }
    }

    return (
        <>
            <h3 className="text-gray-900 normal-case font-semibold tracking-tighter text-2xl m-3">Сканирование моделей</h3>
            <textarea
                type="text"
                ref={inputRef}
                style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                onChange={(e) => setData(e.target.value)}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
            />
            <div className="flex justify-center flex-col gap-3">
                <div className="flex justify-center gap-3 items-center">
                    <Code color={focus ? "success" : "danger"}>Режим сканирования {focus ? <Chip color="success">Включен</Chip> : <Chip color="danger">Выключен</Chip>}</Code>
                    <Code>Этап сканирования <Chip>{type.value}</Chip></Code>
                    <Button color="primary" onClick={handleFocus}>Сканировать</Button>
                </div>
                <ButtonGroup color="secondary">
                    {types.map(x => {
                        return (
                            <Button onClick={() => setType(x)}>{x.value}</Button>
                        )
                    })}
                </ButtonGroup>
            </div>
            <Table aria-label="Таблица сканов" className="mt-10" bottomContent={bottomContent} bottomContentPlacement="outside">
                <TableHeader>
                    {columns.map((column, index) =>
                        <TableColumn key={index}>{column.name}</TableColumn>
                    )}
                </TableHeader>
                <TableBody emptyContent={"Ожидание сканирования"}>
                    {items.map((row, index) =>
                        renderCells(row, index)
                    )}
                </TableBody>
            </Table>
        </>
    )
}