import {useState} from "react";
import { useDropzone } from 'react-dropzone';
import * as XLSX from "xlsx";
import {UploadModelsIcon} from "../../../icons/upload_models";
import {UploadAcceptedModels} from "../../../icons/upload_accepted_models";

export const DropZone = ({data, setData}) => {

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            console.log(rABS, wb);
            const dataLocal = XLSX.utils.sheet_to_json(ws, { header: 1 });
            setData(dataLocal)
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.xlsx',
    });

    return (
        <div {...getRootProps()} className="dropzone text-center cursor-pointer w-full rounded-xl flex justify-center items-center
         h-[150px] bg-neutral-800 p-5 flex-col gap-5 border ">
            <input {...getInputProps()} />
            {data ? <UploadAcceptedModels/> :  <UploadModelsIcon/>}
            <p>Перетащите файл сюда или кликните, чтобы выбрать файл</p>
        </div>
    );
};

